import { ReactElement, createContext, PropsWithChildren, useCallback, useState, useEffect } from 'react';

import {
  ApplicationSupportApplication,
  ApplicationFilters,
  PageQueryOptions,
  SortParams,
  ApplicationSearchMode,
  LeadStatus,
  Permission,
} from '@breathelife/types';

import { useFindApplicationSupportApplicationsQuery } from '../../../../ReactQuery/ApplicationSupport/Application/application.queries';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useSelector } from '../../../../Hooks';
import urls from '../../../../Navigation/Urls';

export const PER_PAGE_OPTIONS = [
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

type ApplicationSupportApplicationsContextValue = {
  applications: ApplicationSupportApplication[];
  total: number;
  isLoading: boolean;
  isSuccess: boolean;
  onPageChange: ($page: number) => void;
  onLimitPerPageChange: ($limit: number) => void;
  onSortingChange: ($sort: SortParams<ApplicationSupportApplication>) => void;
  onSearchChange: ($search?: string) => void;
  onSearchModeChange: ($searchMode?: ApplicationSearchMode) => void;
  onFilterChange: ($filter?: ApplicationFilters) => void;
  currentPage: number;
  perPage: number;
  currentSearchMode: ApplicationSearchMode;
  currentSortOption: SortParams<ApplicationSupportApplication>;
  currentStatusFilter?: LeadStatus;
  currentSearch?: string;
};

const DEFAULT_SORT: SortParams<ApplicationSupportApplication> = {
  field: 'createdAt',
  direction: 'desc',
};

const DEFAULT_SEARCH_MODE = ApplicationSearchMode.application;

export const ApplicationSupportApplicationsContext = createContext<ApplicationSupportApplicationsContextValue>({
  applications: [],
  total: 0,
  isLoading: false,
  isSuccess: true,
  onPageChange: () => {},
  onLimitPerPageChange: () => {},
  onSortingChange: () => {},
  onSearchChange: () => {},
  onSearchModeChange: () => {},
  onFilterChange: () => {},
  perPage: 0,
  currentPage: 1,
  currentSearchMode: DEFAULT_SEARCH_MODE,
  currentSortOption: DEFAULT_SORT,
});

export function ApplicationSupportApplicationsContextProvider(props: PropsWithChildren<{}>): ReactElement {
  const [applicationSupportQueryOptions, setApplicationSupportQueryOptions] = useState<
    PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>
  >({
    $page: 1,
    $limit: PER_PAGE_OPTIONS[1].value,
    $sort: DEFAULT_SORT,
    $searchMode: DEFAULT_SEARCH_MODE,
  });

  const [shouldFetch, setShouldFetch] = useState(false);
  const location = useLocation();
  const user = useSelector((store) => store.leadPlatform.authentication.user);

  useEffect(() => {
    // Check if the user has the 'ApplicationSupportApplicationsRead' permission and is on the application support page.
    if (
      user?.permissions?.includes(Permission.ApplicationSupportApplicationsRead) &&
      location.pathname.startsWith(urls.applicationSupportApplications.fullPath)
    ) {
      setShouldFetch(true);
    } else {
      setShouldFetch(false);
    }
  }, [location.pathname, user]);

  const {
    data: applicationsGetResponse,
    isLoading,
    isSuccess,
  } = useFindApplicationSupportApplicationsQuery(applicationSupportQueryOptions, { enabled: shouldFetch });

  const onPageChange = useCallback(($page: number): void => {
    setApplicationSupportQueryOptions(
      (prevValue: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>) => ({
        ...prevValue,
        $page,
      }),
    );
  }, []);

  const onLimitPerPageChange = useCallback(($limit: number): void => {
    setApplicationSupportQueryOptions(
      (prevValue: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>) => ({
        ...prevValue,
        $limit,
      }),
    );
  }, []);

  const onSortingChange = useCallback(($sort: SortParams<ApplicationSupportApplication>) => {
    setApplicationSupportQueryOptions(
      (prevValue: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>) => ({
        ...prevValue,
        $sort,
      }),
    );
  }, []);

  const onSearchChange = useCallback(($search?: string) => {
    setApplicationSupportQueryOptions(
      (prevValue: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>) => ({
        ...prevValue,
        $page: 1,
        $search,
      }),
    );
  }, []);

  const onSearchModeChange = useCallback(($searchMode?: ApplicationSearchMode) => {
    setApplicationSupportQueryOptions(
      (prevValue: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>) => ({
        ...prevValue,
        $searchMode,
      }),
    );
  }, []);

  const onFilterChange = useCallback(($filter?: ApplicationFilters) => {
    setApplicationSupportQueryOptions(
      (prevValue: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>) => {
        if (!$filter) {
          const newValue = { ...prevValue };
          delete newValue.$filter;
          return newValue;
        }
        return {
          ...prevValue,
          $page: 1,
          $filter: _.merge(prevValue.$filter, $filter),
        };
      },
    );
  }, []);

  return (
    <ApplicationSupportApplicationsContext.Provider
      value={{
        applications: applicationsGetResponse?.data || [],
        total: applicationsGetResponse?.total || 0,
        isLoading,
        isSuccess,
        onPageChange,
        onLimitPerPageChange,
        onSortingChange,
        onSearchChange,
        onSearchModeChange,
        onFilterChange,
        perPage: applicationsGetResponse?.limit || PER_PAGE_OPTIONS[1].value,
        currentPage: applicationSupportQueryOptions.$page || 1,
        currentSearchMode: applicationSupportQueryOptions.$searchMode || DEFAULT_SEARCH_MODE,
        currentSortOption: applicationSupportQueryOptions.$sort || DEFAULT_SORT,
        currentStatusFilter: applicationSupportQueryOptions.$filter?.lead?.status as LeadStatus,
        currentSearch: applicationSupportQueryOptions.$search,
      }}
    >
      {props.children}
    </ApplicationSupportApplicationsContext.Provider>
  );
}
