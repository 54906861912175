import _ from 'lodash';
import { ReactElement, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { hash } from '@breathelife/hash';
import { logger } from '@breathelife/monitoring-frontend';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import { ConsumerFeedbackProvider, LaunchApplicationType, Permission, SignatureType } from '@breathelife/types';

import { RestrictedToUserPermissions } from '../Restricted/RestrictedToUserPermissions';

import { userHasPermission } from '../../Helpers/user';
import { useCarrierContext, useDispatch, useModalState, useNavigation, useSelector } from '../../Hooks';
import {
  generateApplicationDrawerUrl,
  generateApplicationUrl,
  generateLeadDetailUrl,
  generateLeadsListUrl,
} from '../../Navigation/Urls';
import { useGetApplicationQuery } from '../../ReactQuery/Application/application.queries';
import { QueryId } from '../../ReactQuery/common/common.types';
import { fetchAttachments } from '../../ReduxStore/Application/ApplicationOperations';
import { applicationSlice } from '../../ReduxStore/Application/ApplicationSlice';
import { sendIdentityVerificationRequest } from '../../ReduxStore/IdentityVerification/IdentityVerificationOperations';
import { identityVerificationSlice } from '../../ReduxStore/IdentityVerification/IdentityVerificationSlice';
import { paymentSlice } from '../../ReduxStore/Payment/PaymentSlice';
import { AssistedApplicationContainer } from './AssistedApplicationContainer';
import { IdentityVerificationDrawer } from './Drawers/IdentityVerificationDrawer/IdentityVerificationDrawer';
import { FileAttachmentModalContainer } from './Modals/FileAttachment/FileAttachmentModalContainer';
import { SubmissionDetailsModal } from './Modals/SubmissionDetail/SubmissionDetailsModal';
import { SubmitApplicationModalContainer } from './Modals/SubmitApplication/SubmitApplicationModalContainer';
import { JetDecisionWidget } from './Widgets/JetDecision/JetDecisionWidget';
import { useFetchPaymentTransaction } from '../../ReactQuery/Payment/paymentTransaction.queries';
import { getPaymentProviderOperations } from '../../Helpers/payments';
import { AssistedApplicationQuestionnaireVersionContextProvider } from '../../Context/AssistedApplicationQuestionnaireVersionContextProvider';
import { WrappedWithFullPageLoader, useFullPageLoaderContext } from '../LoadingView/FullPageLoader';
import { QuestionnaireEngineProvider } from '../../Hooks/useQuestionnaireEngine';
import { TotalPremiumProvider } from './totalPremiumContext';
import { ModalsContextProvider } from './ModalsContext';
import { ApplicationNotFoundConfirmModal } from './Modals/ErrorUserConfirmation/ApplicationNotFound';
import { defaultState as defaultLayoutState, layoutSlice } from '../../ReduxStore/Layout/LayoutSlice';
import { ApplicationStoredFilesContextProvider } from './Drawers/Documents/ApplicationStoredFilesContext';
import { JetDecisionOutcomesProvider } from '../../Hooks/useJetDecisionOutcomes';

export enum DrawerType {
  signature = 'signature',
}

export function AssistedApplicationChild(): ReactElement | null {
  const { leadTab, leadId, applicationId } = useNavigation();
  const { features } = useCarrierContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openFullPageLoader, isOpen: fullPageLoaderIsOpen, closeFullPageLoader } = useFullPageLoaderContext();

  const { applicationId: applicationIdFromApplicationStore } = useSelector((store) => store.leadPlatform.application);
  const { disableTrackESignInfoModal } = useSelector((store) => store.leadPlatform.assistedApplication);
  const userPermissions = useSelector((store) => store.leadPlatform.authentication.user?.permissions ?? []);

  useEffect(() => {
    if (!fullPageLoaderIsOpen) {
      openFullPageLoader();
    }
    return () => {
      if (fullPageLoaderIsOpen) {
        closeFullPageLoader();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    isApplicationNotFoundConfirmationModalOpen,
    onOpenApplicationNotFoundConfirmationModal,
    onCloseApplicationNotFoundConfirmationModalHook,
  ] = useModalState();

  const onCloseApplicationNotFoundConfirmationModal = useCallback(() => {
    onCloseApplicationNotFoundConfirmationModalHook();

    void queryClient.invalidateQueries([QueryId.leads]);
    void queryClient.invalidateQueries([QueryId.lead, leadId]);

    dispatch(
      layoutSlice.actions.setRightPanelState({
        rightPanelState: defaultLayoutState.rightPanelState,
      }),
    );
    navigate(generateLeadsListUrl(leadTab));
  }, [onCloseApplicationNotFoundConfirmationModalHook, leadTab, navigate, dispatch, queryClient, leadId]);

  const { data: application } = useGetApplicationQuery(applicationId, {
    refetchOnMount: 'always',
    onError: (error: any) => {
      closeFullPageLoader();
      if ('response' in error && 'data' in error.response) {
        const { code } = error.response.data;
        if (code === 404) {
          onOpenApplicationNotFoundConfirmationModal();
        }
      }
    },
  });

  const { isLoading: isLoadingFetchPaymentTransaction = false } = useFetchPaymentTransaction(
    applicationId ?? '',
    { withCardDetails: true, withToken: true },
    {
      enabled: features?.payments?.enabled ?? false,
      refetchOnMount: 'always',
      onError: (err) => {
        logger.error(err);
      },
      onSuccess: (paymentTransaction) => {
        if (features?.payments?.enabled && paymentTransaction) {
          const providerOperations = getPaymentProviderOperations(features?.payments?.serviceProvider);

          if (providerOperations) {
            const { validatePaymentTransactionCompletion } = providerOperations;

            dispatch(
              paymentSlice.actions.setIsPaymentReadyToSubmit(validatePaymentTransactionCompletion(paymentTransaction)),
            );
          }
        }
      },
    },
  );

  const [isInstantIdReportDrawerOpen, onOpenInstantIdReportHook, onCloseInstantIdReportDrawer] = useModalState();
  const [isSubmitApplicationModalOpen, onOpenSubmitApplicationModal, onCloseSubmitApplicationModal] = useModalState();
  const [isSubmissionDetailsModalOpen, onOpenSubmissionDetailsModal, onCloseSubmissionDetailsModal] = useModalState();
  const [isESignatureDetailsOpen, onOpenESignatureDetails, onCloseESignatureDetailsHook] = useModalState();
  const [
    isInfoMessageTrackESignatureModalOpen,
    onOpenInfoMessageTrackESignatureModal,
    onCloseInfoMessageTrackESignatureModal,
  ] = useModalState();
  const [isFileAttachmentModalOpen, onOpenFileAttachmentModalHook, onCloseFileAttachmentModal] = useModalState();

  const [proposedInsuredIndexToDelete, setProposedInsuredIndexToDelete] = useState(-1);
  const onOpenDeleteProposedInsuredModal = (index: number): void => setProposedInsuredIndexToDelete(index);
  const onCloseDeleteProposedInsuredModal = (): void => setProposedInsuredIndexToDelete(-1);

  const [hasInitiatedSubmission, setHasInitiatedSubmission] = useState(false);

  const isJetDecisionWidgetEnabled = useMemo(
    () =>
      features.assistedApplication?.jetDecisionWidget?.enabled &&
      userHasPermission(userPermissions, Permission.JetDecisionWidgetView),
    [userPermissions, features.assistedApplication?.jetDecisionWidget?.enabled],
  );

  const signatureType = useMemo(() => {
    if (
      features.launchApplication.enabled &&
      features.launchApplication.type === LaunchApplicationType.assistedApplication
    ) {
      return features.launchApplication.signatureType;
    }
    return undefined;
  }, [features]);

  const onOpenInstantIdReportDrawer = useCallback(() => {
    TypewriterTracking.clickedButton({
      buttonName: 'See Identity Verification report',
      hashedId: null,
    });
    onOpenInstantIdReportHook();
  }, [onOpenInstantIdReportHook]);

  const onOpenFileAttachmentModal = useCallback(() => {
    TypewriterTracking.clickedButton({
      buttonName: 'files',
      hashedId: application?.id ? hash(application.id) : null,
    });
    onOpenFileAttachmentModalHook();
  }, [application, onOpenFileAttachmentModalHook]);

  const onCloseESignatureDetails = useCallback(() => {
    onCloseESignatureDetailsHook();

    if (hasInitiatedSubmission) {
      // Display track e-sign information modal if the modal is not disabled and the application has been sent for signature
      if (!disableTrackESignInfoModal) {
        onOpenInfoMessageTrackESignatureModal();

        // Run delighted integration if enabled
      } else if (
        features.consumerFeedback.enabled &&
        features.consumerFeedback.provider === ConsumerFeedbackProvider.DELIGHTED &&
        !!features.consumerFeedback.delighted?.csat
      ) {
        document.dispatchEvent(new Event('show-delighted-survey'));
      }
    }
  }, [
    disableTrackESignInfoModal,
    features.consumerFeedback,
    hasInitiatedSubmission,
    onCloseESignatureDetailsHook,
    onOpenInfoMessageTrackESignatureModal,
  ]);

  const triggerIdentityVerification = useCallback(async () => {
    dispatch(identityVerificationSlice.actions.setIsLoading(true));
    return await dispatch(sendIdentityVerificationRequest(application?.id));
  }, [application, dispatch]);

  const closeAssistedApplication = useCallback(() => {
    dispatch(applicationSlice.actions.reset());
    dispatch(paymentSlice.actions.reset());

    void queryClient.invalidateQueries([QueryId.leads]);
    void queryClient.invalidateQueries([QueryId.application]);
    void queryClient.invalidateQueries([QueryId.usedLeadStatuses]);

    if (leadId) {
      void queryClient.invalidateQueries([QueryId.lead, leadId]);
      navigate(generateLeadDetailUrl(leadId, leadTab));
    } else {
      navigate(generateLeadsListUrl(leadTab));
    }
  }, [dispatch, leadId, leadTab, queryClient, navigate]);

  const openSignatureDrawer = useCallback(() => {
    if (!applicationId || !leadId) return;
    navigate(generateApplicationDrawerUrl(applicationId, leadId, leadTab, DrawerType.signature), {
      replace: false,
    });
  }, [navigate, applicationId, leadId, leadTab]);

  const closeSignatureDrawer = useCallback(() => {
    if (!applicationId || !leadId) return;
    onCloseESignatureDetails();
    navigate(generateApplicationUrl(applicationId, leadId, leadTab), { replace: false });
  }, [navigate, applicationId, leadId, leadTab, onCloseESignatureDetails]);

  useEffect(() => {
    if (!application || applicationIdFromApplicationStore === application.id) return;

    if (userHasPermission(userPermissions, Permission.ApplicationFileRead)) {
      void dispatch(fetchAttachments(application.id));
    }
  }, [application, applicationIdFromApplicationStore, dispatch, userPermissions]);

  // TODO: Bring loader lower in the tree when the questionnaire is not loaded cuz the provider will be defined here
  if (!application)
    return (
      <ApplicationNotFoundConfirmModal
        isOpen={isApplicationNotFoundConfirmationModalOpen}
        onClose={onCloseApplicationNotFoundConfirmationModal}
        onConfirm={onCloseApplicationNotFoundConfirmationModal}
        applicationId={applicationId}
      />
    );

  return (
    <Fragment>
      <AssistedApplicationQuestionnaireVersionContextProvider applicationId={application.id}>
        <TotalPremiumProvider application={application}>
          <ModalsContextProvider>
            <JetDecisionOutcomesProvider>
              <QuestionnaireEngineProvider application={application}>
                <ApplicationStoredFilesContextProvider application={application}>
                  <AssistedApplicationContainer
                    application={application}
                    applicationSignatureType={signatureType}
                    closeAssistedApplication={closeAssistedApplication}
                    onNavigateToESignatureDetails={openSignatureDrawer}
                    onOpenESignatureDetails={onOpenESignatureDetails}
                    onOpenSubmissionDetailsModal={onOpenSubmissionDetailsModal}
                    onOpenFileAttachmentModal={onOpenFileAttachmentModal}
                    isESignatureDetailsOpen={isESignatureDetailsOpen}
                    onCloseESignatureDetails={closeSignatureDrawer}
                    isInfoMessageTrackESignatureModalOpen={isInfoMessageTrackESignatureModalOpen}
                    onCloseInfoMessageTrackESignatureModal={onCloseInfoMessageTrackESignatureModal}
                    setHasInitiatedSubmission={setHasInitiatedSubmission}
                    onOpenSubmitApplicationModal={onOpenSubmitApplicationModal}
                    triggerIdentityVerification={triggerIdentityVerification}
                    onOpenInstantIdReportDrawer={onOpenInstantIdReportDrawer}
                    proposedInsuredIndexToDelete={proposedInsuredIndexToDelete}
                    onOpenDeleteProposedInsuredModal={onOpenDeleteProposedInsuredModal}
                    onCloseDeleteProposedInsuredModal={onCloseDeleteProposedInsuredModal}
                    isSubmitApplicationModalOpen={isSubmitApplicationModalOpen}
                    isLoadingFetchPaymentTransaction={isLoadingFetchPaymentTransaction}
                  />
                  <IdentityVerificationDrawer
                    application={application}
                    isOpen={isInstantIdReportDrawerOpen}
                    onClose={onCloseInstantIdReportDrawer}
                  />
                  {application &&
                    (signatureType === SignatureType.cryptoSignature ||
                      signatureType === SignatureType.externalSignature) && (
                      <Fragment>
                        <SubmitApplicationModalContainer
                          signatureType={signatureType}
                          isOpen={isSubmitApplicationModalOpen}
                          closeModal={onCloseSubmitApplicationModal}
                          application={application}
                        />
                        <SubmissionDetailsModal
                          signatureType={signatureType}
                          isOpen={isSubmissionDetailsModalOpen}
                          closeModal={onCloseSubmissionDetailsModal}
                          confirmationNumber={application.confirmationNumber}
                          cryptoSignature={application.private?.signedDigest}
                        />
                      </Fragment>
                    )}
                  <RestrictedToUserPermissions
                    requiredPermissions={[
                      Permission.ApplicationFileCreate,
                      Permission.ApplicationFileRead,
                      Permission.ApplicationFileRemove,
                    ]}
                  >
                    <FileAttachmentModalContainer
                      isOpen={isFileAttachmentModalOpen}
                      closeModal={onCloseFileAttachmentModal}
                      application={application}
                    />
                  </RestrictedToUserPermissions>
                  {isJetDecisionWidgetEnabled && <JetDecisionWidget />}
                </ApplicationStoredFilesContextProvider>
              </QuestionnaireEngineProvider>

              <ApplicationNotFoundConfirmModal
                isOpen={isApplicationNotFoundConfirmationModalOpen}
                onClose={onCloseApplicationNotFoundConfirmationModal}
                onConfirm={onCloseApplicationNotFoundConfirmationModal}
                applicationId={applicationId}
              />
            </JetDecisionOutcomesProvider>
          </ModalsContextProvider>
        </TotalPremiumProvider>
      </AssistedApplicationQuestionnaireVersionContextProvider>
    </Fragment>
  );
}

export const AssistedApplication = WrappedWithFullPageLoader(AssistedApplicationChild);
