import { Fragment, memo, ReactElement, useMemo } from 'react';

import { FieldGenerator, RenderContext, StyleVariant } from '@breathelife/field-generator';
import { RenderingQuestion } from '@breathelife/questionnaire-engine';

import { useCarrierContext, useLanguage } from '../../../../Hooks';
import { FieldGroupCardContainer } from './FieldGroupCard/FieldGroupCardContainer';
import { useQuestionnaireEngine } from '../../../../Hooks/useQuestionnaireEngine';
import { useViewNextIncompleteFieldContext } from '../ViewNextIncompleteFieldContext';

type FieldGroupContainerProps = {
  question: RenderingQuestion;
};

const noop = () => undefined;

export const FieldGroupContainer = memo(InnerFieldGroupContainer);

export function InnerFieldGroupContainer(props: FieldGroupContainerProps): ReactElement {
  const { question } = props;
  const { iconMap } = useCarrierContext();
  const language = useLanguage();
  const { focusedIncompleteFieldId } = useViewNextIncompleteFieldContext();

  const { questionnaireEngine } = useQuestionnaireEngine();

  const onAnswerChange = useMemo(() => {
    return questionnaireEngine.updateAnswers.bind(questionnaireEngine);
  }, [questionnaireEngine]);

  if (!question.visible) return <Fragment />;

  if (question.displayAsCard) {
    return <FieldGroupCardContainer question={question} focusedIncompleteFieldId={focusedIncompleteFieldId} />;
  }

  return (
    <FieldGenerator
      context={RenderContext.web}
      question={question}
      onAnswerChange={onAnswerChange}
      onAnswerComplete={noop}
      styleVariant={StyleVariant.pro}
      iconMap={iconMap}
      locale={language}
      focusedIncompleteFieldId={focusedIncompleteFieldId}
    />
  );
}
