import _ from 'lodash';

import { AnswersChangedSubscriber } from '@breathelife/questionnaire-engine';
import { Application, VersionedAnswers } from '@breathelife/types';

import { updateAssistedApplicationAnswers } from '../../../Services/AssistedApplicationsService';
import { isAnswerTimestampMismatchError } from '../../../Helpers/assistedApplication/answers';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { Dispatch } from '../../../ReduxStore/types';
import { TFunction } from 'i18next';

type ApplicationId = string;

type SaveState = {
  answersToBeSentToBackend: VersionedAnswers;
  lastAnswersSentToBackend: VersionedAnswers | undefined;
  lastAnswersUpdatedAt: Date | null;
  isAwaitingOngoingBackendCall: boolean;
  hasEncounteredError: boolean;
};

type ApplicationState = Record<ApplicationId, SaveState>;

const getDefaultModuleState = (
  lastAnswersUpdatedAt: Date | null,
  answersToBeSentToBackend: VersionedAnswers,
  lastAnswersSentToBackend: VersionedAnswers,
): SaveState => ({
  lastAnswersUpdatedAt,
  answersToBeSentToBackend, // Set by the lambda after initial load.
  lastAnswersSentToBackend,
  isAwaitingOngoingBackendCall: false,
  hasEncounteredError: false,
});

const applicationState: Partial<ApplicationState> = {};
let currentApplication: string = '';

export function unsetCurrentApplication() {
  currentApplication = '';
}

export const sendAnswersToBackend = (
  application: Application,
  openConfirmationModal: () => void,
  setIsAnswersSubscriberActive: (value: boolean) => void,
  dispatch: Dispatch,
  t: TFunction,
): AnswersChangedSubscriber => {
  // #REGION RUN-ONCE-WHEN-SUBSCRIBER-LOADS
  currentApplication = application.id;

  applicationState[application.id] = getDefaultModuleState(
    application.answersUpdatedAt,
    new VersionedAnswers({ v1: _.cloneDeep(application.answers), v2: _.cloneDeep(application.answersV2) }),
    new VersionedAnswers({ v1: _.cloneDeep(application.answers), v2: _.cloneDeep(application.answersV2) }),
  );

  // #END-REGION RUN-ONCE-WHEN-SUBSCRIBER-LOADS

  return async (questionnaireEngine) => {
    setIsAnswersSubscriberActive(true);

    const appState = applicationState[application.id];
    if (!appState) {
      // eslint-disable-next-line no-console
      console.warn('saveAnswersSubscriber should always have a state for that application. ');
      return;
    }

    if (application.submitted) {
      setIsAnswersSubscriberActive(false);
      return;
    }

    const frozenAnswers = _.cloneDeep(questionnaireEngine.getAnswerResolverInstance().dump());

    appState.answersToBeSentToBackend = frozenAnswers;

    if (appState.isAwaitingOngoingBackendCall || frozenAnswers.isEqual(appState.lastAnswersSentToBackend)) {
      setIsAnswersSubscriberActive(false);
      return;
    }

    void callTheNetwork(
      application.id,
      frozenAnswers,
      openConfirmationModal,
      setIsAnswersSubscriberActive,
      dispatch,
      t,
    );
  };
};

async function callTheNetwork(
  applicationId: ApplicationId,
  answersToSend: VersionedAnswers,
  openConfirmationModal: () => void,
  setIsAnswersSubscriberActive: (value: boolean) => void,
  dispatch: Dispatch,
  t: TFunction,
): Promise<void> {
  const appState = applicationState[applicationId];

  if (!appState) {
    return;
  }

  appState.isAwaitingOngoingBackendCall = true;

  try {
    const { answersUpdatedAt } = await updateAssistedApplicationAnswers(applicationId, {
      answers: answersToSend.v1,
      answersV2: answersToSend.v2,
      answersUpdatedAt: appState.lastAnswersUpdatedAt,
    });
    appState.lastAnswersSentToBackend = answersToSend;
    appState.lastAnswersUpdatedAt = new Date(answersUpdatedAt);
  } catch (error: unknown) {
    appState.hasEncounteredError = true;

    if (isAnswerTimestampMismatchError(error)) {
      if (applicationId === currentApplication) {
        openConfirmationModal();
      }
      // We don't throw the mismatchException if we are not on the same application that got the error. We shallow it.
    } else {
      throw error;
    }
  } finally {
    appState.isAwaitingOngoingBackendCall = false;

    if (
      !appState.answersToBeSentToBackend.isEqual(appState.lastAnswersSentToBackend) &&
      !appState.hasEncounteredError
    ) {
      void callTheNetwork(
        applicationId,
        appState.answersToBeSentToBackend,
        openConfirmationModal,
        setIsAnswersSubscriberActive,
        dispatch,
        t,
      );
    } else if (applicationId !== currentApplication) {
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('notifications.saveApplicationSuccess'),
          autoHideDuration: 3000,
        }),
      );
    }

    if (applicationId === currentApplication) {
      setIsAnswersSubscriberActive(false);
    }
  }
}
