import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@breathelife/mui';
import Typography from '../../../../Components/Typography';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { SubmitButton } from '../../../Button/SubmitButton';

type SaveAnswersValidationModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
};

export function SaveAnswersValidationModal(props: SaveAnswersValidationModalProps): ReactElement {
  const { isOpen, onConfirm } = props;
  const { t } = useTranslation();

  return (
    <ModalLayout
      maxWidth='sm'
      title={t('modals.answerSaveValidation.title')}
      isOpen={isOpen}
      hideCancel={true}
      submitButton={
        <SubmitButton data-testid='answer-update-validation-modal-confirm' onClick={onConfirm}>
          {t('modals.answerSaveValidation.submitButton')}
        </SubmitButton>
      }
    >
      <Box>
        <Typography variant='body1'>{t('modals.answerSaveValidation.body')}</Typography>
      </Box>
    </ModalLayout>
  );
}
