import _ from 'lodash';
import { TFunction } from 'i18next';

import { Application, ProductsWidgetFeatureType, QuestionnaireVersionPricingNodeIds } from '@breathelife/types';
import { AnswersChangedSubscriber } from '@breathelife/questionnaire-engine';

import { Dispatch } from '../../../ReduxStore/types';
import { productsSlice } from '../../../ReduxStore/Products/ProductsSlice';
import { getComprehensivePricing } from '../../../Services/AssistedApplicationsService';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { PlatformFeatures } from '../../../Models/PlatformFeatures';

export const createComprehensivePricingSubscriber = (
  features: PlatformFeatures,
  application: Application,
  pricingFieldIdentifiers: QuestionnaireVersionPricingNodeIds | undefined,
  dispatch: Dispatch,
  t: TFunction,
  setIsPricingSubscriberActive: (value: boolean) => void,
): AnswersChangedSubscriber => {
  return async (questionnaireEngine, nodeIds) => {
    setIsPricingSubscriberActive(true);

    const isComprehensivePricingEnabled =
      features.assistedApplication?.productsWidget?.enabled &&
      features.assistedApplication?.productsWidget?.type === ProductsWidgetFeatureType.comprehensive;

    if (!isComprehensivePricingEnabled) {
      setIsPricingSubscriberActive(false);
      return;
    }

    const fieldIdentifiersToWatch =
      features.pricingCalculation.enabled && pricingFieldIdentifiers
        ? pricingFieldIdentifiers.nodeIds
        : features.assistedApplication?.nodeIdsAffectingPricing;

    const shouldRefreshQuotes =
      _.isArray(fieldIdentifiersToWatch) && _.intersection(fieldIdentifiersToWatch, nodeIds).length > 0;

    if (shouldRefreshQuotes) {
      dispatch(productsSlice.actions.setIsLoadingComprehensivePricing(true));

      const versionedAnswers = questionnaireEngine.getAnswerResolverInstance().dump();
      try {
        const result = await getComprehensivePricing(
          application.id,
          versionedAnswers.v1,
          versionedAnswers.v2,
          application.lineOfBusiness,
        );
        dispatch(productsSlice.actions.setComprehensivePricing(result));
      } catch (e: unknown) {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchQuotes'),
          }),
        );
      } finally {
        dispatch(productsSlice.actions.setIsLoadingComprehensivePricing(false));
      }
    }

    setIsPricingSubscriberActive(false);
  };
};
