import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { isRenderingRepeatedQuestion, RenderingQuestion } from '@breathelife/questionnaire-engine';
import { ImageTypes } from '@breathelife/ui-components';

import { FieldGroupCardModal } from '../../../../../Components/AssistedApplication/Modals/FieldGroupCard/FieldGroupCardModal';
import { Icon } from '../../../../../Components/Icons';
import { buildAttributeIdWithIndex } from '../../../../../Helpers/assistedApplication/attributeIds';
import { useModalState } from '../../../../../Hooks';
import { useAssistedApplicationContext } from '../../../../../Hooks/useAssistedApplicationContext';
import { FieldGroupCardPreview } from './FieldGroupCardPreview';
import { AddButton, DeleteButton } from './Styles';
import { useQuestionnaireEngine } from '../../../../../Hooks/useQuestionnaireEngine';

type FieldGroupCardContainerProps = {
  question: RenderingQuestion;
  focusedIncompleteFieldId: string | undefined;
};

export function FieldGroupCardContainer(props: FieldGroupCardContainerProps): ReactElement {
  const { question, focusedIncompleteFieldId } = props;
  const { t } = useTranslation();
  const [isModalOpen, onOpenModal, onCloseModal] = useModalState();
  const { onAnswerChange } = useAssistedApplicationContext();
  const { questionnaireEngine } = useQuestionnaireEngine();

  const hasFocusedIncompleteField = question.fields.some((field) => field.blueprintId === focusedIncompleteFieldId);

  return (
    <Fragment>
      <FieldGroupCardPreview question={question} onEdit={onOpenModal} boldedBorder={hasFocusedIncompleteField} />

      {isRenderingRepeatedQuestion(question) && question.showAddQuestionButton && (
        <AddButton
          variant='text'
          color='primary'
          startIcon={
            <Icon
              name='add'
              variant='primary'
              imageType={ImageTypes.decorative}
              id={buildAttributeIdWithIndex(question.addQuestionButtonText, question.metadata.repetitionIndex + 1)}
            />
          }
          onClick={() => {
            onAnswerChange([
              {
                tag: 'blueprintId',
                blueprintId: question.blueprintId,
                nodeId: question.nodeId,
                value: {},
                effects: question.effects,
                blueprintIdScope: {
                  ...question.metadata.repeatedInstanceIdentifierContext.byBlueprintId,
                  [question.blueprintId]: question.metadata.repetitionIndex + 1,
                },
                nodeIdScope: {
                  ...question.metadata.repeatedInstanceIdentifierContext.byNodeId,
                  [question.nodeId]: question.metadata.repetitionIndex + 1,
                },
              },
            ]);
          }}
        >
          {question.addQuestionButtonText}
        </AddButton>
      )}

      <FieldGroupCardModal
        isOpen={isModalOpen}
        onCloseModal={onCloseModal}
        secondaryAction={
          isRenderingRepeatedQuestion(question) && question.showRemoveQuestionButton ? (
            <DeleteButton
              variant='text'
              startIcon={
                <Icon
                  name='delete'
                  variant='red'
                  imageType={ImageTypes.decorative}
                  id={buildAttributeIdWithIndex(question.removeQuestionButtonText, question.metadata.repetitionIndex)}
                />
              }
              onClick={() => {
                onCloseModal();
                onAnswerChange([
                  {
                    tag: 'blueprintId',
                    blueprintId: question.blueprintId,
                    nodeId: question.nodeId,
                    value: undefined,
                    effects: question.effects,
                    blueprintIdScope: question.metadata.repeatedInstanceIdentifierContext.byBlueprintId,
                    nodeIdScope: question.metadata.repeatedInstanceIdentifierContext.byNodeId,
                  },
                ]);
              }}
            >
              {question.removeQuestionButtonText}
            </DeleteButton>
          ) : (
            <DeleteButton
              variant='text'
              startIcon={<Icon imageType={ImageTypes.decorative} name='delete' variant='red' id='clear' />}
              onClick={() => {
                onCloseModal();
                questionnaireEngine.onBulkAnswerClear(
                  question.fields,
                  question.metadata?.repeatedInstanceIdentifierContext.byBlueprintId || {},
                  question.metadata?.repeatedInstanceIdentifierContext.byNodeId || {},
                );
              }}
            >
              {t('cta.clear')}
            </DeleteButton>
          )
        }
        question={question}
      />
    </Fragment>
  );
}
