import { useMemo } from 'react';

import { PivotAnswerResolver, QuestionnaireEngine, RenderingQuestionnaire } from '@breathelife/questionnaire-engine';
import { DEFAULT_TIMEZONE_NAME, InsuranceModule, RenderingType, Timezone, VersionedAnswers } from '@breathelife/types';

import { getQuestionnaireEngineConfig } from '../../Helpers/questionnaireEngineConfigs';
import { useCarrierContext } from '../../Hooks';
import { ApplicationHookProps } from './types';
import { logger } from '@breathelife/monitoring-frontend';

export function useNeedsAnalysisQuestionnaire(props: ApplicationHookProps): RenderingQuestionnaire | undefined {
  const { features } = useCarrierContext();

  const { application, questionnaire, nodeIdToAnswerPathMap, blueprint } = props;

  const questionnaireEngine = useMemo(() => {
    if (!application || !questionnaire || !nodeIdToAnswerPathMap || !blueprint) return;

    const timezoneResult = Timezone.from(application.timezone || DEFAULT_TIMEZONE_NAME);
    if (timezoneResult.success === false) {
      return;
    }

    const answerResolver = PivotAnswerResolver.from(
      new VersionedAnswers({ v1: application.answers, v2: application.answersV2 }),
      nodeIdToAnswerPathMap,
      blueprint,
      'v1',
      logger,
    );

    return QuestionnaireEngine.from({
      questionnaire,
      answerResolver,
      context: {
        insuranceModules: [InsuranceModule.needsAnalysis],
      },
      config: getQuestionnaireEngineConfig(application.mode, features),
      timezone: timezoneResult.value,
      applicationContext: application.applicationContext,
      text: (key: string) => key,
      renderingOptions: {
        renderingType: RenderingType.proSummary,
        displayErrors: false,
      },
      currentDateOverride: application.submissionDate?.toString() || null,
    });
  }, [application, nodeIdToAnswerPathMap, questionnaire]);

  return questionnaireEngine?.renderingQuestionnaire;
}
