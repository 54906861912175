import { PropsWithChildren, ReactElement, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { TotalPremiums, VersionedAnswers, Application, Language, ProductsWidgetFeatureType } from '@breathelife/types';

import { identityVerificationSlice } from '../../ReduxStore/IdentityVerification/IdentityVerificationSlice';
import ApiService from '../../Services/ApiService';
import { useCarrierContext, useDispatch } from '../../Hooks';
import * as ProductsOperations from '../../ReduxStore/Products/ProductsOperations';

import { QueryId } from '../../ReactQuery/common/common.types';

type TotalPremiumContext = {
  totalPremiums: TotalPremiums | null;
  isFetching: boolean;
  refresh: (appId: string, answers: VersionedAnswers) => Promise<void>;
};

export const context = createContext<TotalPremiumContext | undefined>(undefined);

export const useTotalPremium = () => {
  const totalPremiumContext = useContext(context);

  if (!totalPremiumContext) {
    throw new Error('useTotalPremium is not wrapped under a TotalPremiumProvider.');
  }

  return totalPremiumContext;
};

export function TotalPremiumProvider(props: PropsWithChildren<{ application: Application }>): ReactElement | null {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { features } = useCarrierContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    const isApplicationLineOfBusinessAllowedToFetchProductAndPricing =
      features.assistedApplication?.productsWidget?.enabled &&
      features.assistedApplication?.productsWidget?.allowedLinesOfBusiness.includes(props.application.lineOfBusiness);

    const isProductsWidgetTotalPremiumsEnabled =
      features.assistedApplication?.productsWidget?.enabled &&
      features.assistedApplication?.productsWidget?.type === ProductsWidgetFeatureType.totalPremiums;

    if (!isApplicationLineOfBusinessAllowedToFetchProductAndPricing || isProductsWidgetTotalPremiumsEnabled) {
      return;
    }

    if (isProductsWidgetTotalPremiumsEnabled) {
      void queryClient.invalidateQueries([QueryId.productsWidgetTotalPremiums, props.application.id]);
      return;
    }
    dispatch(ProductsOperations.resetProducts());
    void dispatch(ProductsOperations.fetchProducts(props.application.id, i18n.language as Language));
    void dispatch(
      ProductsOperations.fetchQuotes(
        props.application.id,
        props.application.answers,
        props.application.answersV2,
        props.application.coverageAmount,
      ),
    );
    dispatch(identityVerificationSlice.actions.reset());
  }, [dispatch, i18n.language, props.application.id, features.assistedApplication, props, queryClient]);

  const [state, setState] = useState<{ totalPremiums: TotalPremiums | null; isFetching: boolean }>({
    totalPremiums: null,
    isFetching: false,
  });

  const refresh = useCallback(
    async (appId: string, answers: VersionedAnswers) => {
      setState({ ...state, isFetching: true });
      try {
        const totalPremiums = await ApiService.getProductsWidgetTotalPremiums(appId, answers.v1, answers.v2);
        setState({ totalPremiums, isFetching: false });
      } catch (e) {
        setState({ ...state, isFetching: false });
        throw e;
      }
    },
    [setState],
  );

  return (
    <context.Provider value={{ totalPremiums: state.totalPremiums, isFetching: state.isFetching, refresh }}>
      {props.children}
    </context.Provider>
  );
}
