import { TFunction } from 'i18next';
import _ from 'lodash';
import { ReactElement, useContext, useMemo, useState, useEffect, useCallback, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../Styles/themed-styled-components';
import { RenderingSection, RenderingSubsection } from '@breathelife/questionnaire-engine';
import { ImageTypes } from '@breathelife/ui-components';

import { Icon } from '../../../Components/Icons';
import Typography from '../../../Components/Typography';
import { LeadPlatformTheme } from '../../../Styles/Types';
import { NavButton, SubNavLink, VisuallyHidden, SectionListItem, SubsectionListItem } from './Styles';
import { Box } from '@breathelife/mui';
import { ErrorCounter } from '../../Badge/ErrorCounter';
import { CarrierContext } from '../../../Context/CarrierContext';

export type NavigationRenderingSection = RenderingSection & { visited: boolean };

type Props = {
  scrollContainerRef: RefObject<HTMLDivElement>;
  activeSectionIndex: number;
  onSectionClick: (index: number) => void;
  allSections: NavigationRenderingSection[];
  hasSectionEndIcons?: boolean;
};

const getSectionEndIcon = (
  section: NavigationRenderingSection,
  t: TFunction,
  displayErrorCount: boolean,
): ReactElement | null => {
  if (section.completed) {
    return (
      <Icon
        name='circledCheckMark'
        variant='green'
        inverted
        stroke='none'
        imageType={ImageTypes.meaningful}
        ariaLabel={t('assistedApplication.sectionComplete')}
      />
    );
  }

  if (!section.completed && section.visited) {
    const errorCount = section.numberOfIncompleteFields + section.numberOfInvalidFields;
    return (
      <Box position='relative'>
        <Box>
          <Icon
            name='circledWarning'
            variant='orange'
            inverted
            stroke='none'
            imageType={ImageTypes.meaningful}
            ariaLabel={t('assistedApplication.sectionWarning')}
          />
        </Box>

        {displayErrorCount && <ErrorCounter count={errorCount} withPlacementWrapper max={9} />}
      </Box>
    );
  }

  return null;
};

export function AssistedApplicationNavigation(props: Props): ReactElement {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();

  const { activeSectionIndex, onSectionClick, hasSectionEndIcons = true, allSections, scrollContainerRef } = props;

  const { features } = useContext(CarrierContext);
  return (
    <Box display='flex' flexDirection='column' alignItems='start'>
      <nav role='navigation' aria-label={t('assistedApplication.assistedApplicationNavigationMenu')}>
        <ol role='menu'>
          {allSections.map((section: NavigationRenderingSection, sectionIndex: number) => {
            const isActive = activeSectionIndex === sectionIndex;
            return (
              <SectionListItem key={`AssistedApplication-${section.id}`} role='none'>
                {isActive && <VisuallyHidden>{t('assistedApplication.currentSection')}</VisuallyHidden>}
                <NavButton
                  role='menuitem'
                  $isActive={isActive}
                  textColor={isActive ? theme.colors.grey[90] : theme.colors.grey[70]}
                  onClick={() => onSectionClick(sectionIndex)}
                  endIcon={
                    hasSectionEndIcons ? getSectionEndIcon(section, t, features.incompleteFieldsSupport.enabled) : null
                  }
                  data-testid={`AssistedApplication-${section.id}`}
                >
                  {section.title}
                </NavButton>
                {isActive && (
                  <AssistedApplicationSubNavigation section={section} scrollContainerRef={scrollContainerRef} />
                )}
              </SectionListItem>
            );
          })}
        </ol>
      </nav>
    </Box>
  );
}

function AssistedApplicationSubNavigation(props: {
  section: NavigationRenderingSection;
  scrollContainerRef: RefObject<HTMLDivElement>;
}): ReactElement | null {
  const { section, scrollContainerRef } = props;
  const { t } = useTranslation();
  const scrollContainer = scrollContainerRef.current;

  const [activeSubsectionIndex, setActiveSubsectionIndex] = useState(0);
  const [subsectionScrollData, setSubsectionScrollData] = useState<{ index: number; top: number; bottom: number }[]>(
    [],
  );

  const subsectionsNavigation = useMemo(
    () => section.subsections.filter((subsection) => subsection.visible && subsection.showInNavigation),
    [section.subsections],
  );

  // Fetch the subsection information we need for all subsections: index, top and bottom of subsection
  const extractSubsectionScrollData = useCallback(() => {
    if (!scrollContainer || scrollContainer.scrollHeight === scrollContainer.clientHeight) return; // does not have a scrollbar

    const subsectionHeights: { index: number; top: number; bottom: number }[] = [];
    for (let i = 0; i < subsectionsNavigation.length; i++) {
      const subsectionElement = document.getElementById(_.kebabCase(subsectionsNavigation[i].title));

      if (subsectionElement) {
        const top = subsectionElement.offsetTop !== 0 ? subsectionElement.offsetTop - 1 : 0;
        const bottom = subsectionElement.offsetTop + subsectionElement.scrollHeight;
        subsectionHeights.push({
          index: i,
          top,
          bottom,
        });
      }
    }
    setSubsectionScrollData(subsectionHeights);
  }, [subsectionsNavigation, scrollContainer]);

  // ResizeObserver on scrollContainer children to know if the height has change, because of visibility conditions to add/remove fields
  useEffect(() => {
    if (!scrollContainer) return;
    const observer = new ResizeObserver(() => {
      extractSubsectionScrollData();
    });

    for (const child of scrollContainer.children) {
      observer.observe(child);
    }

    return () => {
      observer.disconnect();
    };
  }, [scrollContainer, extractSubsectionScrollData]);

  // Event listener on the scrollContainer to know which subsection is active
  useEffect(() => {
    if (!scrollContainer || scrollContainer.scrollHeight === scrollContainer.clientHeight) return; // does not have a scrollbar

    const checkActiveSubsection = () => {
      if (!subsectionScrollData.length) {
        extractSubsectionScrollData();
      }

      // if the scrollbar is at the bottom, the last section is active
      if (scrollContainer.scrollHeight - scrollContainer.scrollTop - scrollContainer.clientHeight < 1) {
        setActiveSubsectionIndex(subsectionScrollData[subsectionScrollData.length - 1]?.index || 0);
      } else {
        // find the active subsection with the position of the scroll
        const scrollbarPositionY = scrollContainer.scrollTop + scrollContainer.offsetTop;
        for (const subsection of subsectionScrollData) {
          if (scrollbarPositionY >= subsection.top && scrollbarPositionY <= subsection.bottom) {
            setActiveSubsectionIndex(subsection.index);
            return;
          }
        }
      }
    };

    scrollContainer.addEventListener('scroll', checkActiveSubsection);

    return () => {
      scrollContainer.removeEventListener('scroll', checkActiveSubsection);
    };
  }, [subsectionScrollData, scrollContainer, extractSubsectionScrollData]);

  if (!scrollContainer || !subsectionsNavigation.length) return null;

  const enableScrollableSubsections =
    scrollContainer.scrollHeight !== scrollContainer.clientHeight && subsectionsNavigation.length > 1;

  return (
    <ol role='menu'>
      {subsectionsNavigation.map((subsection: RenderingSubsection, subsectionIndex: number) => {
        const isActive = enableScrollableSubsections ? activeSubsectionIndex === subsectionIndex : false;

        return (
          <SubsectionListItem key={`AssistedApplication-${subsection.id}`} role='none'>
            {isActive && <VisuallyHidden>{t('assistedApplication.currentSubsection')}</VisuallyHidden>}
            <SubNavLink
              role='menuitem'
              $isActive={isActive}
              onClick={() => {
                if (!enableScrollableSubsections) return;
                const subsectionElement = document.getElementById(
                  _.kebabCase(subsectionsNavigation[subsectionIndex].title),
                );
                subsectionElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }}
              $isDisabled={!enableScrollableSubsections}
            >
              <Typography variant='button'>{subsection.title}</Typography>
            </SubNavLink>
          </SubsectionListItem>
        );
      })}
    </ol>
  );
}
