import i18next from 'i18next';
import _ from 'lodash';
import * as yup from 'yup';

import {
  EnforceUniqueEmailStrategy,
  EsignSignatureType,
  ESignSigner2FAInfo as ESignSigner,
  ESignSignerAuthMethod,
  ParticipantRoles,
  PhoneType,
} from '@breathelife/types';

import {
  getAuthMethodSchema,
  getEmailSchema,
  getFirstNameSchema,
  getLastNameSchema,
  getPasscodeSchema,
  getPhoneNumberSchema,
} from '../field';
import { getListValidationError, getValidationError } from '../getValidationError';
import { RecursiveNullable } from '../typeGuards';

export enum SignerFormField {
  fistName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  authMethod = 'authMethod',
  cellphone = 'cellphone',
  passcode = 'passcode',
}

type FillableESignSigner = Omit<ESignSigner, 'roles' | 'documentTypes'>;

const getFieldSchemaMap = (): { [field in SignerFormField]: yup.StringSchema | yup.MixedSchema } => ({
  firstName: getFirstNameSchema().required(i18next.t('validation.error.fname')),
  lastName: getLastNameSchema().required(i18next.t('validation.error.lname')),
  email: getEmailSchema().required(i18next.t('validation.error.required')),
  authMethod: getAuthMethodSchema().required(i18next.t('validation.error.required')),
  cellphone: getPhoneNumberSchema(PhoneType.mobile).required(i18next.t('validation.error.required')),
  passcode: getPasscodeSchema().required(i18next.t('validation.error.required')),
});

const getFormSchemaMap = (
  isInpersonSignature: boolean,
): { [field in SignerFormField]: yup.StringSchema | yup.MixedSchema } => ({
  firstName: getFirstNameSchema().required(i18next.t('validation.error.fname')),
  lastName: getLastNameSchema().required(i18next.t('validation.error.lname')),
  email: getEmailSchema().required(i18next.t('validation.error.fieldIsRequired', { field: i18next.t('inputs.email') })),
  authMethod: !isInpersonSignature
    ? getAuthMethodSchema().required(
        i18next.t('validation.error.fieldIsRequired', {
          field: i18next.t('assistedApplication.eSignatureDetails.authMethod'),
        }),
      )
    : getAuthMethodSchema(),
  cellphone: !isInpersonSignature
    ? getPhoneNumberSchema(PhoneType.mobile).when(['authMethod'], (authMethod, schema, node) => {
        if (authMethod === ESignSignerAuthMethod.cellphone && !node.value)
          return schema.required(
            i18next.t('validation.error.fieldIsRequired', {
              field: i18next.t('assistedApplication.eSignatureDetails.cellphone'),
            }),
          );
        return schema;
      })
    : getPhoneNumberSchema(PhoneType.mobile),
  passcode: !isInpersonSignature
    ? getPasscodeSchema().when(['authMethod'], (authMethod, schema, node) => {
        if (authMethod === ESignSignerAuthMethod.passcode && !node.value)
          return schema.required(
            i18next.t('validation.error.fieldIsRequired', {
              field: i18next.t('assistedApplication.eSignatureDetails.passcode'),
            }),
          );
        return schema;
      })
    : getPasscodeSchema().required(
        i18next.t('validation.error.fieldIsRequired', {
          field: i18next.t('assistedApplication.eSignatureDetails.passcode'),
        }),
      ),
});

/**
 * Determine if signer emails are unique based on the unique email strategy
 *
 * We also check this in the backend.
 *
 * @see `/backend/core/src/actions/signatures/eSignCeremony.actions.ts`
 * @param signers Signer data, which may be null
 * @param enforceUniqueEmailFeature Determines which emails should be checked for uniqueness
 * @returns A boolean indicating if the emails are unique for the given strategy
 */
const validateUniqueSignerEmails = (
  signers: RecursiveNullable<ESignSigner[]>,
  enforceUniqueEmailFeature: EnforceUniqueEmailStrategy,
): boolean => {
  if (!signers) return true;

  if (enforceUniqueEmailFeature === EnforceUniqueEmailStrategy.ADVISORS_ONLY) {
    const agentEmails: string[] = [];
    const otherEmails: string[] = [];

    signers.forEach((signer) => {
      signer?.roles?.find((role) => role?.type === ParticipantRoles.AGENT)
        ? typeof signer?.email === 'string' && agentEmails.push(signer.email.toUpperCase())
        : typeof signer?.email === 'string' && otherEmails.push(signer.email.toUpperCase());
    });

    const uniqueAgentEmails = new Set<string>(agentEmails);
    const uniqueOtherEmails = new Set<string>(otherEmails);
    return agentEmails.length === uniqueAgentEmails.size && agentEmails.every((email) => !uniqueOtherEmails.has(email));
  } else {
    const uniqueSigners = new Set<string>(
      signers.map((signer) => (typeof signer?.email === 'string' ? signer.email.toUpperCase() : '')),
    );

    return signers.length === uniqueSigners.size;
  }
};

// Check that firstName, lastName and email of signers are unique
export function isSignerInfoUnique(signers: RecursiveNullable<ESignSigner[]>): boolean {
  const uniqueSigners = _.uniqWith(
    signers,
    (signer1?, signer2?) =>
      signer1?.firstName?.toUpperCase() === signer2?.firstName?.toUpperCase() &&
      signer1?.lastName?.toUpperCase() === signer2?.lastName?.toUpperCase() &&
      signer1?.email?.toUpperCase() === signer2?.email?.toUpperCase(),
  );

  return uniqueSigners.length === signers?.length;
}

function getFormSchema(
  signatureType: EsignSignatureType,
  enforceUniqueEmailFeature: EnforceUniqueEmailStrategy,
): yup.NotRequiredArraySchema<RecursiveNullable<FillableESignSigner>> {
  const schema = yup
    .array()
    .of(yup.object().shape(getFormSchemaMap(signatureType === EsignSignatureType.inPerson)))
    .test(
      'is-email-unique',
      i18next.t(
        enforceUniqueEmailFeature === EnforceUniqueEmailStrategy.ADVISORS_ONLY
          ? 'validation.error.duplicateAdvisorsEmail'
          : 'validation.error.duplicateSignersEmail',
      ),
      (signers) => validateUniqueSignerEmails(signers as RecursiveNullable<ESignSigner>[], enforceUniqueEmailFeature),
    )

    .test('is-signer-info-unique', i18next.t('validation.error.duplicateSignersInfo'), (signers) =>
      isSignerInfoUnique(signers as RecursiveNullable<ESignSigner>[]),
    );
  return schema as yup.NotRequiredArraySchema<RecursiveNullable<FillableESignSigner>>;
}

export function getFieldValidationError(
  fieldName: SignerFormField,
  value: string | undefined,
): yup.ValidationError | undefined {
  return getValidationError(getFieldSchemaMap(), fieldName, value);
}

export function getFormValidationError(
  signers: ESignSigner[],
  signatureType: EsignSignatureType,
  enforceUniqueEmailFeature: EnforceUniqueEmailStrategy = EnforceUniqueEmailStrategy.ALL,
): yup.ValidationError | undefined {
  return getListValidationError<FillableESignSigner>(getFormSchema(signatureType, enforceUniqueEmailFeature), signers);
}
