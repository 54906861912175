import { Permission, User } from '@breathelife/types';
import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { AssistedApplication } from '../Components/AssistedApplication/AssistedApplication';
import { DebugToolbarContainer } from '../Components/DebugToolbar/DebugToolbarContainer';

import { Notification } from '../Components/Notification/Notification';
import { userHasPermission } from '../Helpers/user';
import { useCarrierContext, useSelector } from '../Hooks';
import { useAdminTabs } from '../Hooks/useAdminTabs';
import { LeadsPageLayout } from '../Layouts/LeadsPageLayout/LeadsPageLayout';
import { PageLayout } from '../Layouts/PageLayout/PageLayout';
import { LeadTab } from '../Models/Layout';
import Urls, { generateLeadsListUrl } from '../Navigation/Urls';
import { ApplicationOverviewContainer } from '../Pages/Admin/ApplicationSupport/ApplicationOverview/ApplicationOverviewContainer';
import { ApplicationsViewContainer } from '../Pages/Admin/ApplicationSupport/ApplicationsViewContainer';
import { AssociatedFilesPage } from '../Pages/Admin/ApplicationSupport/AssociatedFiles/AssociatedFilesPage';
import { ESignatureViewContainer } from '../Pages/Admin/ApplicationSupport/ESignature/ESignatureViewContainer';
import { UnderwritingReportsViewContainer } from '../Pages/Admin/ApplicationSupport/UnderwritingReports/UnderwritingReportsViewContainer';
import { DocumentManagementViewContainer } from '../Pages/Admin/Document/DocumentManagementViewContainer';
import { FirmManagementViewContainer } from '../Pages/Admin/Firm/FirmManagementViewContainer';
import { AuditLogsViewContainer } from '../Pages/Admin/Logs/AuditLogsViewContainer';
import { ProductManagementViewContainer } from '../Pages/Admin/Product/ProductManagementViewContainer';
import { QuestionnaireVersionManagementViewContainer } from '../Pages/Admin/Questionnaire/QuestionnaireVersionManagementViewContainer';
import { QuestionnaireVersionsContextProvider } from '../Pages/Admin/Questionnaire/ContextProvider/QuestionnaireVersionsContextProvider';
import { QuestionnaireBuilderView } from '../Pages/Admin/QuestionnaireBuilder/QuestionnaireBuilderView';
import { SalesDecisionRulesManagementViewContainer } from '../Pages/Admin/SalesDecisionRules/SalesDecisionRulesManagementViewContainer';
import { SettingsViewContainer } from '../Pages/Admin/Settings/SettingsViewContainer';
import { ThemeManagementViewContainer } from '../Pages/Admin/Theme/ThemeManagementViewContainer';
import { ThirdPartyIntegrationsView } from '../Pages/Admin/ThirdPartyIntegrations/ThirdPartyIntegrationsView';
import { UserManagementViewContainer } from '../Pages/Admin/UserManagement/UserManagementViewContainer';
import { LeadsListView } from '../Pages/Home/LeadsListView/LeadsListView';
import { LeadsPageDataContextProvider } from '../Pages/Leads/LeadsPageDataContextProvider';
import AuthCallbackPage from '../Pages/Login/AuthCallback/AuthCallbackContainer';
import LoginPage from '../Pages/Login/LoginContainer';
import LogoutPage from '../Pages/Login/LogoutContainer';
import { PrivateRootApplication } from '../Root/PrivateRootApplication';
import RequiredAuth from '../Root/RequiredAuth';
import { QuestionnaireManagementViewContainer } from '../Pages/Admin/Questionnaire/QuestionnaireManagementViewContainer';
import { ApplicationSupportPage } from '../Pages/Admin/ApplicationSupport/ApplicationSupportPage';
import { PricingFieldIdentifiersManagementViewContainer } from '../Pages/Admin/PricingFieldIdentifiers/PricingFieldIdentifiersManagementViewContainer';
import { AdHocSignatureRequestsView } from '../Pages/Admin/AdHocSignatureRequests/AdHocSignatureRequestsView';
import { AdHocSignatureRequestView } from '../Pages/Admin/AdHocSignatureRequests/AdHocSignatureRequestView';
import { NotificationCentreContextProvider } from '../Pages/Home/NotificationCentre/NotificationCentreContextProvider';
import { UserMenuContextProvider } from '../Context/UserMenuContextProvider';
import { ApplicationSupportApplicationsContextProvider } from '../Pages/Admin/ApplicationSupport/Applications/ApplicationsContextProvider';

export function Root(): ReactElement {
  const location = useLocation();
  const { features } = useCarrierContext();
  const { t } = useTranslation();
  const user = useSelector((store) => store.leadPlatform.authentication.user);

  const isLeadCreationEnabled = features.leadCreation.enabled;
  const leadTabs = [
    {
      to: generateLeadsListUrl(LeadTab.active),
      label: isLeadCreationEnabled ? t('leadsListTable.active') : t('applicationTable.active'),
      ['data-testid']: 'leads-list-tab-active',
    },
    {
      to: generateLeadsListUrl(LeadTab.archived),
      label: isLeadCreationEnabled ? t('leadsListTable.archived') : t('applicationTable.archived'),
      ['data-testid']: 'leads-list-tab-archive',
    },
  ];
  const adminTabs = useAdminTabs();

  return (
    <Fragment>
      <Notification />
      {/* Since react-router v6, nested route works with Outlets (https://reactrouter.com/en/main/components/outlet#outlet). Each parent route contains render an "<Outlet />" component which is responsible to render the child route.  */}
      <Routes>
        <Route element={<RequiredAuth />}>
          {/* Leads routes */}
          <Route
            path={Urls.leads.path}
            element={
              <UserMenuContextProvider>
                <NotificationCentreContextProvider>
                  <PrivateRootApplication />
                </NotificationCentreContextProvider>
              </UserMenuContextProvider>
            }
          >
            <Route index element={<Navigate to={{ pathname: leadTabs[0].to, search: location.search }} replace />} />
            <Route
              path={Urls.leadsList.path}
              element={
                <LeadsPageLayout pageTitle={t('pageTitles.home')} tabs={leadTabs}>
                  {features.questionnaireDebugTools.enabled && <DebugToolbarContainer />}
                  <LeadsPageDataContextProvider />
                </LeadsPageLayout>
              }
            >
              <Route index element={<LeadsListView />} />
              <Route path={Urls.leadDetail.path} element={<LeadsListView />}></Route>
            </Route>
          </Route>
          <Route
            path={Urls.leads.path}
            element={
              <UserMenuContextProvider>
                <NotificationCentreContextProvider>
                  <PrivateRootApplication displayContentFullScreen />
                </NotificationCentreContextProvider>
              </UserMenuContextProvider>
            }
          >
            <Route path={Urls.application.path} element={<AssistedApplication />}>
              <Route path={Urls.applicationDrawer.path} />
            </Route>
          </Route>

          <Route
            element={
              <ApplicationSupportApplicationsContextProvider>
                <Outlet />
              </ApplicationSupportApplicationsContextProvider>
            }
          >
            {/* Admin routes */}
            <Route
              element={
                <UserMenuContextProvider>
                  <NotificationCentreContextProvider>
                    <PrivateRootApplication displayContentFullScreen />
                  </NotificationCentreContextProvider>
                </UserMenuContextProvider>
              }
            >
              <Route element={<PageLayout tabs={adminTabs} />}>
                <Route
                  index
                  path={Urls.admin.path}
                  element={<Navigate to={{ pathname: getRootRedirect(user), search: location.search }} replace />}
                />
                <Route path={Urls.userManagement.path} element={<UserManagementViewContainer />} />
                <Route path={Urls.productManagement.path} element={<ProductManagementViewContainer />} />
                <Route path={Urls.themeManagement.path} element={<ThemeManagementViewContainer />} />
                <Route path={Urls.settingsManagement.path} element={<SettingsViewContainer />} />
                <Route path={Urls.auditLogs.path} element={<AuditLogsViewContainer />} />
                <Route path={Urls.firmManagement.path} element={<FirmManagementViewContainer />} />
                <Route path={Urls.documentsManagement.path} element={<DocumentManagementViewContainer />} />
                <Route path={Urls.questionnaireBuilder.path} element={<QuestionnaireBuilderView />} />
                <Route path={Urls.thirdPartyIntegrations.path} element={<ThirdPartyIntegrationsView />} />
                <Route
                  path={Urls.salesDecisionRulesManagement.path}
                  element={
                    <QuestionnaireVersionsContextProvider>
                      <SalesDecisionRulesManagementViewContainer />
                    </QuestionnaireVersionsContextProvider>
                  }
                />
                <Route
                  path={Urls.questionnaireManagement.path}
                  element={
                    <QuestionnaireVersionsContextProvider>
                      <QuestionnaireManagementViewContainer />
                    </QuestionnaireVersionsContextProvider>
                  }
                >
                  <Route
                    path={Urls.questionnaire.path}
                    element={
                      <QuestionnaireVersionsContextProvider>
                        <QuestionnaireManagementViewContainer />
                      </QuestionnaireVersionsContextProvider>
                    }
                  />
                </Route>
                <Route
                  path={Urls.questionnaireVersion.path}
                  element={<QuestionnaireVersionManagementViewContainer />}
                />
                {/* This path has to live within the admin route because it is available inside the admin layout.
            Once an application is selected, we stay under the admin route, but we get a new layout from the "applications/:applicationsId" route */}
                <Route path={Urls.applicationSupportApplications.path} element={<ApplicationsViewContainer />} />
                <Route
                  path={Urls.pricingFieldIdentifiersManagement.path}
                  element={
                    <QuestionnaireVersionsContextProvider>
                      <PricingFieldIdentifiersManagementViewContainer />
                    </QuestionnaireVersionsContextProvider>
                  }
                />
                <Route path={Urls.adHocSignatureRequest.path} element={<AdHocSignatureRequestView />} />
                <Route path={Urls.adHocSignatureRequests.path} element={<AdHocSignatureRequestsView />} />
              </Route>
            </Route>

            {/* ApplicationSupport routes */}
            <Route
              element={
                <UserMenuContextProvider>
                  <PrivateRootApplication displayContentFullScreen />
                </UserMenuContextProvider>
              }
            >
              {/* The nested routes when an application is selected from the application support ApplicationsViewContainer. The layout is different. */}
              <Route
                path={Urls.applicationSupportApplication.path}
                element={
                  <NotificationCentreContextProvider>
                    <ApplicationSupportPage />
                  </NotificationCentreContextProvider>
                }
              >
                <Route path={Urls.applicationSupportOverview.path} element={<ApplicationOverviewContainer />} />
                <Route
                  path={Urls.applicationSupportUnderwritingReports.path}
                  element={<UnderwritingReportsViewContainer />}
                />
                <Route path={Urls.applicationSupportAssociatedFiles.path} element={<AssociatedFilesPage />} />
                <Route path={Urls.applicationSupportESignature.path} element={<ESignatureViewContainer />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path={Urls.login.path} element={<LoginPage />} />
        <Route path={Urls.logout.path} element={<LogoutPage />} />
        <Route path={Urls.authCallback.path} element={<AuthCallbackPage />} />
        <Route
          path='*'
          element={<Navigate to={{ pathname: Urls.leads.fullPath, search: location.search }} replace />}
        />
      </Routes>
    </Fragment>
  );
}

// TODO HOT-2105 This is temporary until we have a landing page in the admin that does not requires the same level of permission as showing the Admin button
export function getRootRedirect(user: User | undefined): string {
  if (user?.permissions?.length) {
    if (userHasPermission(user.permissions, Permission.QuestionnaireUpdate)) {
      return Urls.questionnaireManagement.fullPath;
    }
    if (userHasPermission(user.permissions, Permission.ApplicationSupportApplicationsRead)) {
      return Urls.applicationSupportApplications.fullPath;
    }
  }
  return Urls.leads.fullPath;
}
