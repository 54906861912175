import _ from 'lodash';
import { Box } from '@breathelife/mui';
import { SetStateAction, ReactElement, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import dayjs from 'dayjs';
import { getAge } from '@breathelife/date-helpers';
import { hash } from '@breathelife/hash';
import {
  IncompleteFieldIdentifier,
  isRenderingQuestionnaireComplete,
  RenderingRepeatedSectionGroup,
} from '@breathelife/questionnaire-engine';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import {
  LocalizedInsuranceProduct,
  Permission,
  SignatureType,
  ESignSigner2FAInfo,
  ESignCeremonyStatus,
  ApplicationMode,
  AgeRoundingType,
  ProductsWidgetFeatureType,
} from '@breathelife/types';
import { GoogleMapsHelmet } from '@breathelife/ui-components';

import { MemoizedAssistedApplicationHeader as AssistedApplicationHeader } from '../../Components/AssistedApplication/AssistedApplicationView/AssistedApplicationHeader';
import { MemoizedAssistedApplicationView as AssistedApplicationView } from '../../Components/AssistedApplication/AssistedApplicationView/AssistedApplicationView';
import { RestrictedToUserPermissions } from '../Restricted/RestrictedToUserPermissions';
import { AssistedApplicationContext, ProposedInsuredTab } from '../../Context/AssistedApplicationContext';
import { getApplicantFullName, getInsuredDateOfBirth, SectionGroupId } from '../../Helpers/questionnaireAnswers';
import { useCarrierContext, useModalState, useSelector, useDispatch, useNavigation } from '../../Hooks';
import { Application } from '../../Models/Application';
import { useSubmitInsuranceApplication } from '../../ReactQuery/Application/application.mutations';
import { useGetApplicationQuery } from '../../ReactQuery/Application/application.queries';
import {
  useSendESignCeremonyMutation,
  useUpdateESignCeremony,
} from '../../ReactQuery/ESignCeremony/eSignCeremony.mutations';
import { useGetESignCeremonyQuery } from '../../ReactQuery/ESignCeremony/eSignCeremony.queries';
import { useGetPointOfSaleDecisions } from '../../ReactQuery/PointOfSaleDecisions/pointOfSaleDecisions.queries';
import { QueryId } from '../../ReactQuery/common/common.types';
import {
  processApplicationSubmission,
  processPreviewApplicationSubmission,
} from '../../ReduxStore/Application/ApplicationOperations';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { SkipLink } from '../SkipLink/SkipLink';
import { DocumentsDrawer } from './Drawers/Documents/DocumentsDrawer';
import { ESignatureDetailsContainer } from './Drawers/ESignatureDetails/ESignatureDetailsContainer';
import { InfoMessageTrackESignatureModal } from './Modals/ESignature/InfoMessageTrackESignatureModal';
import { SaveAnswersValidationModal } from './Modals/SaveAnswersValidation/SaveAnswersValidationModal';
import { SubmitPaperAppModal } from './Modals/SubmitPaperApp/SubmitPaperAppModal';
import { resetStoredSectionState, useStoredSectionState } from './useStoredSectionState';
import { canOnlyReadApplication } from '../../Helpers/permissions';
import { userHasPermission } from '../../Helpers/user';
import { useGetLeadQuery } from '../../ReactQuery/Lead/lead.queries';
import { NodeIds } from '@breathelife/insurance-form-builder';
import { deleteAllOutcomesForParticipant } from '../../Services/ApplicationOutcomesService';
import { useFullPageLoaderContext } from '../LoadingView/FullPageLoader';
import { DrawerType } from './AssistedApplication';
import { useGetProductsEntityQuery } from '../../ReactQuery/ProductsEntity/productsEntity.queries';
import { useQuestionnaireEngine } from '../../Hooks/useQuestionnaireEngine';

import { ApplicationStoredFilesContextProvider } from './Drawers/Documents/ApplicationStoredFilesContext';
import {
  getSectionGroupIdAndIndexFromSectionGroupWithIndicesId,
  getSectionGroupWithIndicesId,
  makeSectionIdsToVisibleSectionIndicesMapKey,
  SectionGroupIndices,
  SectionIdsToVisibleSectionIndicesMap,
  updateVisitedSectionIndices,
} from '../../Helpers/assistedApplication/sectionGroups';
import { ViewNextIncompleteFieldContextProvider } from './AssistedApplicationView/ViewNextIncompleteFieldContext';
import { useModals } from './ModalsContext';
import { useFetchComprehensivePricingMutation } from '../../ReactQuery/AssistedApplication/assistedApplication.mutations';
import { unsetCurrentApplication } from './Subscribers/saveAnswersSubscriber';

export type NumberOfVisibleErrorsBySectionGroup = Array<{
  sectionGroupId: string;
  numberOfErrors: number;
  completed: boolean;
}>;

type Props = {
  application: Application;
  applicationSignatureType?: SignatureType;
  closeAssistedApplication: () => void;
  onNavigateToESignatureDetails: () => void;
  onOpenESignatureDetails: () => void;
  onOpenSubmissionDetailsModal: () => void;
  onOpenFileAttachmentModal: () => void;
  isESignatureDetailsOpen: boolean;
  onCloseESignatureDetails: () => void;
  isInfoMessageTrackESignatureModalOpen: boolean;
  onCloseInfoMessageTrackESignatureModal: () => void;
  setHasInitiatedSubmission: (value: SetStateAction<boolean>) => void;
  onOpenSubmitApplicationModal: () => void;
  triggerIdentityVerification: () => void;
  onOpenInstantIdReportDrawer: () => void;
  proposedInsuredIndexToDelete: number;
  onOpenDeleteProposedInsuredModal: (index: number) => void;
  onCloseDeleteProposedInsuredModal: () => void;
  isSubmitApplicationModalOpen: boolean;
  isLoadingFetchPaymentTransaction: boolean;
};

const ADD_REMOVE_INSURED_LOADER_DELAY = 1000;

export function AssistedApplicationContainer(props: Props): ReactElement | null {
  const { t } = useTranslation();
  const { carrierInfo, features, googleMapsPlaces: googleMapsApiKey } = useCarrierContext();

  const { questionnaireEngine, renderingQuestionnaire, hasActiveSubscribers } = useQuestionnaireEngine();

  const { drawerType } = useNavigation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { closeFullPageLoader, openFullPageLoader } = useFullPageLoaderContext();

  const {
    application,
    applicationSignatureType,
    closeAssistedApplication,
    onOpenSubmitApplicationModal,
    onNavigateToESignatureDetails,
    onOpenESignatureDetails,
    onOpenSubmissionDetailsModal,
    onOpenFileAttachmentModal,
    isESignatureDetailsOpen,
    onCloseESignatureDetails,
    setHasInitiatedSubmission,
    isInfoMessageTrackESignatureModalOpen,
    onCloseInfoMessageTrackESignatureModal,
    triggerIdentityVerification,
    onOpenInstantIdReportDrawer,
    proposedInsuredIndexToDelete,
    onOpenDeleteProposedInsuredModal,
    onCloseDeleteProposedInsuredModal,
    isSubmitApplicationModalOpen,
    isLoadingFetchPaymentTransaction,
  } = props;

  const { isSubmittingApplication } = useSelector((state) => state.leadPlatform.submission);
  const { products, quotes, isLoadingQuotes } = useSelector((state) => state.leadPlatform.products);
  const userPermissions = useSelector((store) => store.leadPlatform.authentication.user?.permissions ?? []);
  const isIdentityVerificationCheckCompleted = useSelector(
    (store) => store.leadPlatform.identityVerification.isIdentityVerificationCheckCompleted,
  );

  const fetchComprehensivePricingMutation = useFetchComprehensivePricingMutation();
  const sendESignCeremonyMutation = useSendESignCeremonyMutation();
  const updateESignCeremonyStatusMutation = useUpdateESignCeremony();
  const submitInsuranceApplicationMutation = useSubmitInsuranceApplication();
  const { data: eSignCeremony, isFetching: isGetESignCeremonyFetching } = useGetESignCeremonyQuery(
    application?.id,
    applicationSignatureType,
  );
  const { isFetching: isGetApplicationFetching } = useGetApplicationQuery(application?.id);
  const { isFetching: isPointOfSalesDecisionsFetching } = useGetPointOfSaleDecisions(application?.id);

  const [isDocumentDrawerOpen, onOpenDocumentDrawerHook, onCloseDocumentDrawer] = useModalState();
  const [isSubmitPaperAppModalOpen, onOpenSubmitPaperAppModal, onCloseSubmitPaperAppModal] = useModalState();
  const { closeSaveAnswersValidationModal, isSaveAnswersValidationModalOpen } = useModals();
  const [storedSectionIndices, setStoredSectionIndices] = useStoredSectionState(application?.id);

  const [openESignatureDrawerRequested, setOpenESignatureDrawerRequested] = useState(false);
  const [shouldPointOfSalesTriggerLoadingStatus, setShouldPointOfSalesTriggerLoadingStatus] = useState(true);

  const { data: lead } = useGetLeadQuery(application?.leadId, {});
  const userCanOnlyReadApplication = canOnlyReadApplication(lead);

  const productWidgetType = useMemo(() => {
    const isProductWidgetEnabled = features.assistedApplication?.productsWidget?.enabled;
    const isApplicationLineOfBusinessAllowed =
      isProductWidgetEnabled &&
      features.assistedApplication?.productsWidget?.allowedLinesOfBusiness.includes(application.lineOfBusiness);
    if (!isProductWidgetEnabled || !isApplicationLineOfBusinessAllowed) return undefined;
    return features.assistedApplication?.productsWidget?.type;
  }, [features.assistedApplication?.productsWidget, application.lineOfBusiness]);

  const isProductsEntityEnabled =
    features.assistedApplication?.productsWidget?.enabled && productWidgetType === ProductsWidgetFeatureType.default;

  const isIdentityVerificationCheckEnabled = !!features.enableInstantIdRequest?.enabled;
  const isMissingIdentityVerificationCheck =
    isIdentityVerificationCheckEnabled && !isIdentityVerificationCheckCompleted;
  const minorAge = carrierInfo.minorAge ?? 0;

  const { activeSectionIndex, previousSectionIndex, currentSectionGroupId, currentProposedInsuredIndex } =
    storedSectionIndices;

  const isESignStatusChanging =
    updateESignCeremonyStatusMutation.isLoading ||
    submitInsuranceApplicationMutation.isLoading ||
    sendESignCeremonyMutation.isLoading ||
    isGetApplicationFetching ||
    (isPointOfSalesDecisionsFetching && shouldPointOfSalesTriggerLoadingStatus) ||
    (isGetESignCeremonyFetching && eSignCeremony?.status !== ESignCeremonyStatus.IN_PROGRESS);

  const isJetDecisionWidgetEnabled = useMemo(
    () =>
      features.assistedApplication?.jetDecisionWidget?.enabled &&
      userHasPermission(userPermissions, Permission.JetDecisionWidgetView),
    [userPermissions, features.assistedApplication?.jetDecisionWidget?.enabled],
  );

  const { data: productsEntity, refetch: refetchProductsEntity } = useGetProductsEntityQuery(
    questionnaireEngine.getAnswerResolverInstance().dump().v1,
    questionnaireEngine.getAnswerResolverInstance().dump().v2,
    products as LocalizedInsuranceProduct[],
    quotes,
    application,
    {
      enabled: !!isProductsEntityEnabled,
    },
  );

  useEffect(() => {
    if (isProductsEntityEnabled) {
      void refetchProductsEntity();
    }
  }, [products, quotes]);

  useEffect(() => {
    const isComprehensivePricingEnabled =
      features.assistedApplication?.productsWidget?.enabled &&
      features.assistedApplication?.productsWidget?.type === ProductsWidgetFeatureType.comprehensive;

    if (!application || !isComprehensivePricingEnabled) {
      return;
    }

    void fetchComprehensivePricingMutation.mutate({
      applicationId: application.id,
      answers: application.answers,
      answersV2: application.answersV2,
      lineOfBusinessName: application.lineOfBusiness,
    });
  }, [application, features, dispatch, questionnaireEngine]);

  const areAllFieldsDisabled = useMemo(
    () =>
      application.signed ||
      application.submitted ||
      isSubmittingApplication ||
      isESignStatusChanging ||
      userCanOnlyReadApplication,
    [
      application.signed,
      application.submitted,
      isSubmittingApplication,
      isESignStatusChanging,
      userCanOnlyReadApplication,
    ],
  );

  useEffect(() => {
    questionnaireEngine.setReadonly(areAllFieldsDisabled);
  }, [areAllFieldsDisabled]);

  const applicantName = useMemo(() => {
    return getApplicantFullName(questionnaireEngine, application.lineOfBusiness, features);
  }, [renderingQuestionnaire, questionnaireEngine, application.lineOfBusiness, features]);

  const applicantDateOfBirth = useMemo(() => {
    return getInsuredDateOfBirth(questionnaireEngine);
  }, [renderingQuestionnaire, questionnaireEngine]);

  const isApplicantMinor = useMemo(() => {
    if (minorAge === 0) return false;
    const applicantDateOfBirthObject = dayjs(applicantDateOfBirth);
    const applicantAge = getAge(
      applicantDateOfBirthObject.toDate(),
      AgeRoundingType.lastBirthday,
      application.submissionDate || null,
    );
    const isMinor = applicantAge < minorAge;
    return isMinor;
  }, [applicantDateOfBirth, minorAge]);

  const [visitedSectionIndicesByGroup, setVisitedSectionsIndicesByGroup] = useState<
    SectionGroupIndices[] | undefined
  >();

  const [numberOfVisibleErrorsBySectionGroup, incompleteFieldIdentifiers, sectionIdsToVisibleSectionIndicesMap]: [
    NumberOfVisibleErrorsBySectionGroup,
    IncompleteFieldIdentifier[],
    SectionIdsToVisibleSectionIndicesMap,
  ] = useMemo(() => {
    const numberOfErrorsMap: NumberOfVisibleErrorsBySectionGroup = [];
    const incompleteFields: IncompleteFieldIdentifier[] = [];
    const mapOfSectionIdsToSectionIndex: SectionIdsToVisibleSectionIndicesMap = {};

    for (const renderingSectionGroup of renderingQuestionnaire) {
      let numberOfErrors = 0;
      let sectionGroupInVisitedSectionGroups = null;

      for (const visitedSectionGroup of visitedSectionIndicesByGroup || []) {
        if (
          (visitedSectionGroup.id.includes('insuredPeople.') &&
            visitedSectionGroup.id.replace('insuredPeople.', '') ===
              (renderingSectionGroup as RenderingRepeatedSectionGroup).surrogateId) ||
          visitedSectionGroup.id === renderingSectionGroup.id
        ) {
          sectionGroupInVisitedSectionGroups = visitedSectionGroup;
        }
      }

      let currentSectionIndexInVisibleSections = -1;
      // Count the number of fields missing in each section and add the section's incomplete fields to the global incompleteFields
      for (const section of renderingSectionGroup.sections) {
        if (section.visible) {
          currentSectionIndexInVisibleSections++;
          const key = makeSectionIdsToVisibleSectionIndicesMapKey(section.blueprintId, renderingSectionGroup.id);

          // Update the map of section blueprintIds to visible section index
          mapOfSectionIdsToSectionIndex[key] = currentSectionIndexInVisibleSections;

          // If section has been visited
          if (
            sectionGroupInVisitedSectionGroups &&
            sectionGroupInVisitedSectionGroups.indices.includes(currentSectionIndexInVisibleSections) &&
            !renderingSectionGroup.completed
          ) {
            numberOfErrors += section.numberOfIncompleteFields || 0;
            numberOfErrors += section.numberOfInvalidFields || 0;
            Array.prototype.push.apply(incompleteFields, section.incompleteFieldIdentifiers);
          }
        }
      }
      numberOfErrorsMap.push({
        sectionGroupId: renderingSectionGroup.id,
        numberOfErrors,
        completed: renderingSectionGroup.completed,
      });
    }

    return [numberOfErrorsMap, incompleteFields, mapOfSectionIdsToSectionIndex];
  }, [renderingQuestionnaire, visitedSectionIndicesByGroup]);

  const isQuestionnaireCompleted = useMemo(
    () => isRenderingQuestionnaireComplete(renderingQuestionnaire),
    [renderingQuestionnaire],
  );

  const proposedInsuredTabs = useMemo(() => {
    const proposedInsuredCount = questionnaireEngine.getRepetitionCount(NodeIds.insuredPeople, {}) || 0;

    const tabs = [];
    for (let proposedInsuredIndex = 0; proposedInsuredIndex < proposedInsuredCount; proposedInsuredIndex++) {
      const numberOfVisibleErrorsForSectionGroup = numberOfVisibleErrorsBySectionGroup.find((visitedSectionGroup) => {
        const { sectionGroupIndex } = getSectionGroupIdAndIndexFromSectionGroupWithIndicesId(
          visitedSectionGroup.sectionGroupId,
        );

        return sectionGroupIndex?.toString() === proposedInsuredIndex.toString();
      });

      const scope = { ['insured-people']: proposedInsuredIndex };
      const surrogateId = questionnaireEngine
        .getAnswerResolverInstance()
        .usingNodeId()
        .getInstanceId(NodeIds.insuredPeople, scope)
        .withDefault(undefined);

      const tabData: ProposedInsuredTab = {
        index: proposedInsuredIndex,
        surrogateId: surrogateId as string,
        name: '',
        numberOfErrors: numberOfVisibleErrorsForSectionGroup?.numberOfErrors || 0,
        completed: numberOfVisibleErrorsForSectionGroup?.completed || false,
      };

      const firstName = questionnaireEngine.getAnswer(NodeIds.insuredFirstName, scope);
      const lastName = questionnaireEngine.getAnswer(NodeIds.insuredLastName, scope);

      if (firstName || lastName) {
        tabData.name = [firstName, lastName].filter(Boolean).join(' ');
      }

      tabs.push(tabData);
    }

    return tabs;
  }, [questionnaireEngine, numberOfVisibleErrorsBySectionGroup]);

  const hashedApplicationId = useMemo(() => hash(application.id), [application.id]);

  /** TODO: see DEV-7251
   * We should have a better way of keeping track of an application's signature status regardless of the type of signature used
   * hasSignature is used to know if something is signed in the context of cryptoSignature
   * in the context of eSignature, we only check if the submission was created (application.submitted)
   * */
  const hasNoSignature =
    application.mode !== ApplicationMode.paper &&
    applicationSignatureType !== SignatureType.eSignature &&
    !application.signed;
  const DECOUPLE_ESIGN_SUBMISSION_ENABLED = !!features.enableDecoupleESignFlow?.enabled;

  // Application is being edited by the user
  const isApplicationInEditMode =
    DECOUPLE_ESIGN_SUBMISSION_ENABLED && eSignCeremony?.status === ESignCeremonyStatus.DRAFT;

  // Application has been locked for review
  const isApplicationInReview =
    DECOUPLE_ESIGN_SUBMISSION_ENABLED &&
    // application has been submitted but eSignCeremony status is still in draft
    ((application.submitted && eSignCeremony?.status === ESignCeremonyStatus.DRAFT) ||
      eSignCeremony?.status === ESignCeremonyStatus.IN_PROGRESS ||
      eSignCeremony?.status === ESignCeremonyStatus.READY);

  const isSubmitButtonVisible = isApplicationInReview || !application.submitted || hasNoSignature;

  const isSubmitButtonDisabled = useMemo(
    () =>
      !application ||
      (application.submitted && application.signed) ||
      isSubmitApplicationModalOpen ||
      isLoadingFetchPaymentTransaction ||
      !isQuestionnaireCompleted ||
      (isMissingIdentityVerificationCheck && !isApplicantMinor) ||
      isLoadingQuotes ||
      isApplicationInEditMode ||
      userCanOnlyReadApplication ||
      hasActiveSubscribers,
    [
      application,
      isSubmitApplicationModalOpen,
      isLoadingFetchPaymentTransaction,
      isLoadingQuotes,
      isApplicantMinor,
      isApplicationInEditMode,
      isMissingIdentityVerificationCheck,
      isQuestionnaireCompleted,
      userCanOnlyReadApplication,
      hasActiveSubscribers,
    ],
  );

  const DOCUMENTS_DRAWER_ENABLED = features.assistedApplication?.documentsDrawer?.enabled === true;

  const onAnswerComplete = useCallback(
    (fieldId: string) => {
      TypewriterTracking.completedField({
        fieldId,
        hashedId: hashedApplicationId,
      });
    },
    [hashedApplicationId],
  );

  const onOpenDocumentsDrawer = useCallback(() => {
    TypewriterTracking.clickedButton({
      buttonName: 'documents drawer',
      hashedId: application?.id ? hash(application.id) : null,
    });
    onOpenDocumentDrawerHook();
  }, [application, onOpenDocumentDrawerHook]);

  const processSubmission = useCallback(
    async (signers: ESignSigner2FAInfo[] = [], inPerson: boolean = false) => {
      if (!application || !applicationSignatureType) return;
      const premiumForSelectedProduct = productsEntity?.totalPremium;
      let updatedApplication: Application | undefined;
      if (application.isPreview) {
        updatedApplication = await dispatch(
          processPreviewApplicationSubmission({ application, productQuote: premiumForSelectedProduct }),
        );
      } else if (applicationSignatureType === SignatureType.eSignature) {
        if (signers.length === 0 && application.mode === ApplicationMode.digital) {
          throw new Error('Failed to get signers for application.');
        }
        updatedApplication = await dispatch(
          processApplicationSubmission({
            application,
            productQuote: premiumForSelectedProduct,
            signatureType: applicationSignatureType,
            signers,
            // TODO [DEV-10295] https://breathelife.atlassian.net/browse/DEV-10295 Don't pass this down once we migrate AA submission to react-query
            sendESignCeremonyMutation,
            inPerson,
          }),
        );
      } else {
        updatedApplication = await dispatch(
          processApplicationSubmission({
            application,
            productQuote: premiumForSelectedProduct,
            signatureType: applicationSignatureType,
          }),
        );
      }
      setHasInitiatedSubmission(true);
      if (updatedApplication) {
        queryClient.setQueryData([QueryId.application, application.id], updatedApplication);
        void queryClient.invalidateQueries([QueryId.application, application.id]);
        void queryClient.invalidateQueries([QueryId.pointOfSaleDecision, application.id]);
      }
    },
    [
      application,
      applicationSignatureType,
      productsEntity?.totalPremium,
      setHasInitiatedSubmission,
      queryClient,
      dispatch,
      sendESignCeremonyMutation,
    ],
  );

  const onSubmitApplication = useCallback(async () => {
    if (
      !application ||
      !applicationSignatureType ||
      (!application.isPreview && applicationSignatureType === SignatureType.eSignature)
    )
      return;
    onOpenSubmitApplicationModal();
    return processSubmission();
  }, [application, applicationSignatureType, onOpenSubmitApplicationModal, processSubmission]);

  const handleConfirmSubmitPaperAppModal = useCallback(async () => {
    onCloseSubmitPaperAppModal();
    try {
      await processSubmission();
      dispatch(
        notificationSlice.actions.setSuccess({
          message: t('modals.submitPaperApp.submitSuccessMessage'),
        }),
      );
      closeAssistedApplication();
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: t('modals.submitPaperApp.submitErrorMessage'),
        }),
      );
    }
  }, [onCloseSubmitPaperAppModal, processSubmission, closeAssistedApplication, dispatch, t]);

  const onAnswerChange = useMemo(() => {
    return questionnaireEngine.updateAnswers.bind(questionnaireEngine);
  }, [questionnaireEngine]);

  // TODO: Probably can delete that hook usage. Its not used anymore.
  // const { data: participants } = useProcessParticipantsQuery(application?.id, {
  //   enabled: applicationSignatureType === SignatureType.eSignature && isQuestionnaireCompleted,
  // });

  const handleConfirmSaveAnswersValidationModal = useCallback(async () => {
    closeSaveAnswersValidationModal();

    // TODO: seamless answer reload and rendering questionnaire redraw without reloading the whole page
    // https://zinnia.atlassian.net/browse/HOT-5098
    window.location.reload();
  }, [closeSaveAnswersValidationModal]);

  const onLockApplication: () => void = useCallback(async () => {
    return;
  }, []);

  const onUnlockApplication: () => void = useCallback(async () => {
    updateESignCeremonyStatusMutation.mutate({
      applicationId: application.id,
      data: { status: ESignCeremonyStatus.DRAFT },
    });
  }, [application.id, updateESignCeremonyStatusMutation]);

  useEffect(() => {
    if (isSubmitButtonDisabled || !drawerType) return;
    if (drawerType === DrawerType.signature) {
      onOpenESignatureDetails();
    }
  }, [application, drawerType, onOpenESignatureDetails, isSubmitButtonDisabled]);

  useEffect(() => {
    if (!openESignatureDrawerRequested) return;
    if (!isSubmitButtonDisabled) {
      setShouldPointOfSalesTriggerLoadingStatus(false);
      onNavigateToESignatureDetails();
    } else {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.unableToSendToSignature'),
        }),
      );
    }
    setOpenESignatureDrawerRequested(false);
  }, [isSubmitButtonDisabled, openESignatureDrawerRequested, onNavigateToESignatureDetails, dispatch, t]);

  const onSubmitESignature = (signers: ESignSigner2FAInfo[], inPerson: boolean): void => {
    if (DECOUPLE_ESIGN_SUBMISSION_ENABLED) {
      updateESignCeremonyStatusMutation.mutate({
        applicationId: application.id,
        data: { status: ESignCeremonyStatus.SENT, signers },
      });
    } else {
      void processSubmission(signers, inPerson);
    }
  };

  async function onOpenESignatureDrawer(): Promise<void> {
    !application.submitted;
    setOpenESignatureDrawerRequested(true);
  }

  function onSetActiveSectionIndex(newActiveSectionIndex: number): void {
    setStoredSectionIndices((prev) => {
      return {
        ...prev,
        activeSectionIndex: newActiveSectionIndex,
      };
    });
  }
  const setActiveSectionAsVisited = () => {
    const surrogateId = proposedInsuredTabs[currentProposedInsuredIndex]?.surrogateId ?? '';

    const sectionIndicesByGroupId = getSectionGroupWithIndicesId(currentSectionGroupId, surrogateId);

    updateVisitedSectionIndices({
      activeSectionIndex,
      sectionIndicesByGroupId,
      setVisitedSectionsIndicesByGroup,
      visitedSectionIndicesByGroup,
    });
  };

  const onSelectProposedInsuredIndex = useCallback(
    (newProposedInsuredIndex: number): void => {
      setActiveSectionAsVisited();
      setStoredSectionIndices((prev) => {
        return {
          ...prev,
          currentProposedInsuredIndex: newProposedInsuredIndex,
        };
      });
      onSetActiveSectionIndex(0);
      window.scrollTo(0, 0);
    },
    [setStoredSectionIndices, onSetActiveSectionIndex],
  );

  const onRemoveProposedInsured = useCallback(
    async (surrogateId: string): Promise<void> => {
      if (areAllFieldsDisabled) return;

      openFullPageLoader(ADD_REMOVE_INSURED_LOADER_DELAY);
      if (!application || surrogateId === '') {
        closeFullPageLoader();
        return;
      }
      questionnaireEngine.removeItemFromCollection(surrogateId, NodeIds.insuredPeople, NodeIds.insuredSurrogateId);
      void deleteAllOutcomesForParticipant({ participantId: surrogateId, applicationId: application.id });
      onCloseDeleteProposedInsuredModal();
      onSelectProposedInsuredIndex(0);
      closeFullPageLoader();
      if (isJetDecisionWidgetEnabled) {
        void queryClient.invalidateQueries([QueryId.jetDecisionOutcomes, application.id]);
      }
    },
    [
      queryClient,
      onSelectProposedInsuredIndex,
      onCloseDeleteProposedInsuredModal,
      isJetDecisionWidgetEnabled,
      application,
      areAllFieldsDisabled,
      closeFullPageLoader,
      openFullPageLoader,
      questionnaireEngine,
    ],
  );

  function onSwitchSectionGroupTab(sectionGroupId: SectionGroupId): void {
    setActiveSectionAsVisited();
    if (sectionGroupId !== currentSectionGroupId) {
      setStoredSectionIndices((prev) => {
        return {
          ...prev,
          previousSectionIndex: activeSectionIndex,
          activeSectionIndex: previousSectionIndex,
        };
      });
    }
    setStoredSectionIndices((prev) => {
      return {
        ...prev,
        currentSectionGroupId: sectionGroupId,
      };
    });
  }

  const onAddProposedInsured = useCallback(async (): Promise<void> => {
    if (areAllFieldsDisabled) return;
    openFullPageLoader();
    if (!application) {
      closeFullPageLoader();
      return;
    }

    const insuredPeopleCount = questionnaireEngine.getRepetitionCount(NodeIds.insuredPeople, {});
    questionnaireEngine.createInstanceByNodeId(NodeIds.insuredPeople, {
      [NodeIds.insuredPeople]: insuredPeopleCount || 0,
    });
    onSelectProposedInsuredIndex(insuredPeopleCount || 0);
    closeFullPageLoader();
    if (isJetDecisionWidgetEnabled && application) {
      void queryClient.invalidateQueries([QueryId.jetDecisionOutcomes, application.id]);
    }
  }, [
    questionnaireEngine,
    areAllFieldsDisabled,
    application,
    isJetDecisionWidgetEnabled,
    onSelectProposedInsuredIndex,
    queryClient,
    closeFullPageLoader,
    openFullPageLoader,
  ]);

  // Display the esign loader when sending the eSignCeremony to sign
  const isESignatureDetailLoading = DECOUPLE_ESIGN_SUBMISSION_ENABLED
    ? updateESignCeremonyStatusMutation.isLoading
    : sendESignCeremonyMutation.isLoading;

  const skipToAssistedApplicationContentId = 'assistedApplicationContent';

  return (
    <AssistedApplicationContext.Provider
      value={{
        // Proposed insured
        currentSectionGroupId,
        onSwitchSectionGroupTab,
        proposedInsuredTabs,
        currentProposedInsuredIndex,
        onSelectProposedInsured: onSelectProposedInsuredIndex,
        onAddProposedInsured,
        onRemoveProposedInsured,
        activeSectionIndex,
        onSetActiveSectionIndex,
        onAnswerChange, // Otherwise this is lost and will be undefined within the class.

        // Form
        areAllFieldsDisabled,
        onAnswerComplete,

        // Application
        applicationLineOfBusiness: application.lineOfBusiness,
        productWidgetType,
      }}
    >
      <ApplicationStoredFilesContextProvider application={application}>
        <ViewNextIncompleteFieldContextProvider
          incompleteFieldIdentifiers={incompleteFieldIdentifiers}
          visitedSectionIndicesByGroup={visitedSectionIndicesByGroup || []}
          sectionIdsToVisibleSectionIndicesMap={sectionIdsToVisibleSectionIndicesMap}
        >
          <Box display='flex' flexDirection='column' height='100vh'>
            <Box>
              <SkipLink href={`#${skipToAssistedApplicationContentId}`} title={t('skipLinks.header')} />
              {googleMapsApiKey && <GoogleMapsHelmet googleMapsApiKey={googleMapsApiKey} />}
              <AssistedApplicationHeader
                applicantName={applicantName}
                isApplicantMinor={isApplicantMinor}
                applicationSignatureType={applicationSignatureType}
                applicationMode={application.mode}
                close={() => {
                  unsetCurrentApplication();
                  closeAssistedApplication();
                  resetStoredSectionState();
                }}
                isPreview={application.isPreview}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
                isSubmitButtonVisible={isSubmitButtonVisible}
                isLeadArchived={!!lead?.archived}
                onSubmitApplication={onSubmitApplication}
                onOpenESignatureDetails={onOpenESignatureDrawer}
                onOpenFileAttachmentModal={onOpenFileAttachmentModal}
                onOpenDocumentsDrawer={onOpenDocumentsDrawer}
                triggerIdentityVerification={triggerIdentityVerification}
                onOpenInstantIdReportDrawer={onOpenInstantIdReportDrawer}
                onOpenSubmitPaperAppModal={onOpenSubmitPaperAppModal}
                numberOfVisibleErrorsBySectionGroup={numberOfVisibleErrorsBySectionGroup}
                showCompletionIcons={application.mode !== ApplicationMode.paper}
              />
            </Box>
            <Box id={skipToAssistedApplicationContentId}>
              <AssistedApplicationView
                renderingQuestionnaire={renderingQuestionnaire}
                selectedProductId={application.product}
                applicationMode={application.mode}
                isApplicationSubmitted={application.submitted}
                isApplicationSigned={application.signed}
                isQuestionnaireCompleted={isQuestionnaireCompleted}
                onOpenSubmissionDetailsModal={onOpenSubmissionDetailsModal}
                productsEntity={productsEntity}
                proposedInsuredIndexToDelete={proposedInsuredIndexToDelete}
                onOpenDeleteProposedInsuredModal={onOpenDeleteProposedInsuredModal}
                onCloseDeleteProposedInsuredModal={onCloseDeleteProposedInsuredModal}
                onLockApplication={onLockApplication}
                onUnlockApplication={onUnlockApplication}
                onOpenESignatureDetails={onOpenESignatureDrawer}
                isESignStatusChanging={isESignStatusChanging}
                applicationSignatureType={applicationSignatureType}
                isReadOnly={userCanOnlyReadApplication}
                visitedSectionIndicesByGroup={visitedSectionIndicesByGroup}
                setVisitedSectionsIndicesByGroup={setVisitedSectionsIndicesByGroup}
                numberOfVisibleErrorsBySectionGroup={numberOfVisibleErrorsBySectionGroup}
                showCompletionIcons={application.mode !== ApplicationMode.paper}
              />
            </Box>
          </Box>

          {DOCUMENTS_DRAWER_ENABLED && (
            <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationFileRead]}>
              <DocumentsDrawer
                isOpen={isDocumentDrawerOpen}
                onClose={onCloseDocumentDrawer}
                application={application}
              />
            </RestrictedToUserPermissions>
          )}

          {applicationSignatureType === SignatureType.eSignature && (
            <Fragment>
              <ESignatureDetailsContainer
                isLoading={isESignatureDetailLoading}
                isOpen={isESignatureDetailsOpen}
                onClose={onCloseESignatureDetails}
                onSubmit={onSubmitESignature}
              />
              <InfoMessageTrackESignatureModal
                isOpen={isInfoMessageTrackESignatureModalOpen}
                onClose={onCloseInfoMessageTrackESignatureModal}
              />
            </Fragment>
          )}
          {application.mode === ApplicationMode.paper && (
            <Fragment>
              <SubmitPaperAppModal
                isOpen={isSubmitPaperAppModalOpen}
                onClose={onCloseSubmitPaperAppModal}
                onConfirm={handleConfirmSubmitPaperAppModal}
              />
              <InfoMessageTrackESignatureModal
                isOpen={isInfoMessageTrackESignatureModalOpen}
                onClose={onCloseInfoMessageTrackESignatureModal}
              />
            </Fragment>
          )}
        </ViewNextIncompleteFieldContextProvider>
      </ApplicationStoredFilesContextProvider>
      <SaveAnswersValidationModal
        isOpen={isSaveAnswersValidationModalOpen}
        onConfirm={handleConfirmSaveAnswersValidationModal}
      />
    </AssistedApplicationContext.Provider>
  );
}
