import _ from 'lodash';
import { QueryClient } from 'react-query';

import {
  Application,
  InstanceScope,
  Language,
  ProductsWidgetFeatureType,
  QuestionnaireVersionPricingNodeIds,
  QuoteInfo,
} from '@breathelife/types';
import { AnswersChangedSubscriber } from '@breathelife/questionnaire-engine';
import { NodeIds } from '@breathelife/insurance-form-builder';

import { hasIncludes } from '../../../Helpers/assistedApplication/pricing';
import { FetchQuotesMutation } from '../../../ReactQuery/AssistedApplication/assistedApplication.mutations';
import { productsSlice } from '../../../ReduxStore/Products/ProductsSlice';
import * as ProductsOperations from '../../../ReduxStore/Products/ProductsOperations';
import { Dispatch } from '../../../ReduxStore/types';
import { PlatformFeatures } from '../../../Models/PlatformFeatures';
import { identityVerificationSlice } from '../../../ReduxStore/IdentityVerification/IdentityVerificationSlice';
import { QueryId } from '../../../ReactQuery/common/common.types';

// TODO: Extract that function and import it when we create the questionnaireEngine.
export const fetchQuotesAndMaybeProducts = (
  application: Application,
  dispatch: Dispatch,
  queryClient: QueryClient,
  language: Language,
  features: PlatformFeatures,
  pricingFieldIdentifiers: QuestionnaireVersionPricingNodeIds | undefined,
  fetchQuotesMutation: FetchQuotesMutation,
  setIsQuotesSubscriberActive: (value: boolean) => void,
): AnswersChangedSubscriber => {
  return async (questionnaireEngine, ids): Promise<void> => {
    setIsQuotesSubscriberActive(true);
    const isProductsWidgetEnabled =
      features.assistedApplication?.productsWidget?.enabled &&
      features.assistedApplication?.productsWidget?.type &&
      [ProductsWidgetFeatureType.totalPremiums, ProductsWidgetFeatureType.default].includes(
        features.assistedApplication?.productsWidget?.type,
      );
    const isApplicationLineOfBusinessAllowedToFetchProductAndPricing =
      features.assistedApplication?.productsWidget?.enabled &&
      features.assistedApplication?.productsWidget?.allowedLinesOfBusiness.includes(application.lineOfBusiness);

    if (!isApplicationLineOfBusinessAllowedToFetchProductAndPricing || !isProductsWidgetEnabled) {
      setIsQuotesSubscriberActive(false);
      return;
    }

    const shouldRefreshProducts =
      _.isArray(features.assistedApplication?.nodeIdsAffectingProducts) &&
      _.intersection(features.assistedApplication?.nodeIdsAffectingProducts, ids).length > 0;

    const isProductsWidgetTotalPremiumsEnabled =
      features.assistedApplication?.productsWidget?.enabled &&
      features.assistedApplication?.productsWidget?.type === ProductsWidgetFeatureType.totalPremiums;

    if (isProductsWidgetTotalPremiumsEnabled) {
      void queryClient.invalidateQueries([QueryId.productsWidgetTotalPremiums, application.id]);
      if (shouldRefreshProducts) {
        await dispatch(ProductsOperations.fetchProducts(application.id, language));
      }
      setIsQuotesSubscriberActive(false);
      return;
    }

    // TODO: Maybe extra all the remaining rows of the function into their own action for products that is checked on every questionnaire update?
    if (shouldRefreshProducts) {
      await dispatch(ProductsOperations.fetchProducts(application.id, language));
    }

    let shouldFetchQuotes = false;

    const fieldIdentifiersToWatch =
      features.pricingCalculation.enabled && pricingFieldIdentifiers
        ? pricingFieldIdentifiers.nodeIds
        : features.assistedApplication?.nodeIdsAffectingPricing;

    const nodeIdAffectingPricingHasChanged =
      _.isArray(fieldIdentifiersToWatch) && _.intersection(fieldIdentifiersToWatch, ids).length > 0;

    if (nodeIdAffectingPricingHasChanged) {
      shouldFetchQuotes = true;
    }

    const currentAnswers = questionnaireEngine.getAnswerResolverInstance().dump();
    const pricingIncludes = hasIncludes({
      answers: currentAnswers,
      featureConfigsAdoMinMax: features.assistedApplication?.adoMinMax,
      featureConfigsESA: features.assistedApplication?.equivalentSingleAge,
      updatedNodeIds: ids,
      nodeIdsAffectingPricing: features.assistedApplication.nodeIdsAffectingPricing,
      questionnaireEngine,
      pricingFieldIdentifiers,
    });

    if (pricingIncludes.ado || pricingIncludes.esa) {
      shouldFetchQuotes = true;
    }

    let quotesInfo: QuoteInfo | null = null;
    if (shouldFetchQuotes) {
      quotesInfo = await fetchQuotesMutation.mutateAsync({
        appId: application.id,
        answers: currentAnswers.v1,
        answersV2: currentAnswers.v2,
        includeADO: pricingIncludes.ado,
        includeESA: pricingIncludes.esa,
      });
      dispatch(productsSlice.actions.setQuotes(quotesInfo));
    }

    if (quotesInfo?.answers) {
      const nodesToRefresh: {
        nodeId: string;
        value: number;
        scope: InstanceScope;
      }[] = [];

      quotesInfo?.answers.map((item) => {
        const scope: InstanceScope = {
          [NodeIds.insuredPeople]: item.insuredIndex,
        };

        questionnaireEngine.updateAnswer({
          tag: 'nodeId',
          nodeId: item.nodeId,
          value: item.value,
          nodeIdScope: scope,
        });
        nodesToRefresh.push({
          nodeId: item.nodeId,
          value: item.value,
          scope,
        });
      });
    }

    if (shouldRefreshProducts) {
      await dispatch(ProductsOperations.fetchProducts(application.id, language));
    }

    // TODO: Guillaume: Maybe that should be in an identity verification check subscriber ???
    dispatch(identityVerificationSlice.actions.reset());

    setIsQuotesSubscriberActive(false);
  };
};
