import { Fragment, ReactElement, useCallback, useState, SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Box, Collapse, RadioGroup, Radio, IconButton, Popover } from '@breathelife/mui';
import { InfoVariants } from '@breathelife/questionnaire-engine';
import {
  ApplicationPointOfSaleDecisions,
  ESignCeremony,
  ESignSigner2FAInfo,
  OutcomeCode,
  EsignSignatureType,
  ESignCeremonyStatus,
} from '@breathelife/types';
import { ImageTypes, Information } from '@breathelife/ui-components';

import { Icon } from '../../../../../Components/Icons';
import LoadingView from '../../../../../Components/LoadingView/LoadingView';
import { Tabs, TabsContainer, TabType } from '../../../../../Components/Tabs/Tabs';
import { getSignerForParticipant } from '../../../../../Helpers/eSignSigners/insuredEntitiesToESignSigner';
import { useDispatch, useSelector, useCarrierContext } from '../../../../../Hooks';
import { RequiredFile } from '../../../../../Hooks/Application';
import { Application } from '../../../../../Models/Application';
import { useGetPointOfSaleDecisions } from '../../../../../ReactQuery/PointOfSaleDecisions/pointOfSaleDecisions.queries';
import { assistedApplicationSlice } from '../../../../../ReduxStore/AssistedApplication/AssistedApplicationSlice';
import { DocumentToSign } from './DocumentToSign';
import { SignerInfo } from './SignerInfo';
import { SignerInfoForm } from './SignerInfoForm';
import { StyledFormControlLabel } from '../../../../../Components/Checkbox/Styles';
import Typography from '../../../../../Components/Typography';
import styled from '../../../../../Styles/themed-styled-components';
import { SubmitButton } from '../../../../../Components/Button/SubmitButton';

const InformationBox = styled(Information)`
  margin: 0;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledSubmitButton = styled(SubmitButton)`
  max-width: 100px;

  span {
    line-height: 1;
  }
`;

type SignerInfoViewProps = {
  isEditMode: boolean;
  isLoading: boolean;
  isESignCeremonyLoading: boolean;
  application: Application;
  signatureType: EsignSignatureType;
  onSignatureTypeChange: (signatureType: EsignSignatureType) => void;
  onUpdateSigner: (signer: ESignSigner2FAInfo, idx: number) => void;
  requiredFiles: RequiredFile[];
  signers: ESignSigner2FAInfo[];
  eSignCeremony: ESignCeremony | null | undefined;
};

export function SignerInfoView(props: SignerInfoViewProps): ReactElement | null {
  const {
    application,
    isEditMode,
    isLoading,
    onUpdateSigner,
    requiredFiles,
    signers,
    eSignCeremony,
    isESignCeremonyLoading,
    signatureType,
    onSignatureTypeChange,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { features } = useCarrierContext();

  const isInPersonSignatureEnabled = features.eSignature.inPerson;

  const [infoPopoverAnchorEl, setInfoPopoverAnchorEl] = useState(null);

  const infoBoxDetails = useMemo(() => {
    // If ceremony has an error status
    if (
      eSignCeremony &&
      [
        ESignCeremonyStatus.EMAIL_BOUNCED,
        ESignCeremonyStatus.OPT_OUT,
        ESignCeremonyStatus.DECLINED,
        ESignCeremonyStatus.SIGNER_LOCKED_OUT,
        ESignCeremonyStatus.EXPIRED,
        ESignCeremonyStatus.SEND_PACKAGE_ERROR,
      ].includes(eSignCeremony?.status)
    ) {
      return {
        loading: false,
        disabled: true,
        infoVariant: InfoVariants.error,
        message: 'signingUrlErrorMessage',
      };
    }

    // If ceremony is missing the signing url
    const isSigningUrlReady = !!(!isESignCeremonyLoading && eSignCeremony?.signingUrl);
    if (!isSigningUrlReady) {
      return {
        loading: true,
        disabled: true,
        infoVariant: InfoVariants.info,
        message: 'signingUrlLoadingMessage',
      };
    }

    return {
      loading: false,
      disabled: false,
      infoVariant: InfoVariants.success,
      message: 'signingUrlSuccessMessage',
    };
  }, [eSignCeremony, isESignCeremonyLoading]);

  const { data: pointOfSaleDecisions } = useGetPointOfSaleDecisions(application.id, {
    enabled: !isLoading, // Refetch when eSignCeremony status changes and/or when submitting
    staleTime: 0, // Always refetch.
  });

  const { isDocumentSignInfoBoxOpen } = useSelector((state) => state.leadPlatform.assistedApplication);

  const [tabValue, setTabValue] = useState(0);

  const tabs: TabType[] = [
    { label: t('assistedApplication.signatureDrawer.signersInformation') },
    { label: t('assistedApplication.signatureDrawer.documentsToSign') },
  ];

  const handleTabChange = useCallback((_: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const closeInfoBox = useCallback(() => {
    void dispatch(assistedApplicationSlice.actions.setIsDocumentSignInfoBoxOpen(false));
  }, [dispatch]);

  const handleInfoPopoverClose = useCallback(() => {
    setInfoPopoverAnchorEl(null);
  }, []);

  const isInfoModalOpen = Boolean(infoPopoverAnchorEl);

  if (isLoading) {
    return <LoadingView />;
  }

  if (signers.length == 0) {
    return null;
  }

  return (
    <Box>
      <TabsContainer borderBottom={1} borderTop={1}>
        <Box px={4}>
          <Tabs value={tabValue} onChange={handleTabChange} tabs={tabs} />
        </Box>
      </TabsContainer>

      <Box py={2}>
        {tabValue === 0 && (
          <Fragment>
            {isInPersonSignatureEnabled && (
              <Fragment>
                <Box display='flex' alignItems='center'>
                  <Box display='flex' alignItems='center' px={2}>
                    <Typography variant='body1'>
                      {t('assistedApplication.eSignatureDetails.signatureType.title')}
                    </Typography>
                    <IconButton
                      sx={{ ml: 0.5 }}
                      aria-owns={isInfoModalOpen ? 'info-popover' : undefined}
                      aria-haspopup='true'
                      onMouseEnter={(e: any) => {
                        setInfoPopoverAnchorEl(e.currentTarget);
                      }}
                      onMouseLeave={handleInfoPopoverClose}
                    >
                      <Icon name='info' variant='primary' width='16' height='16' imageType={ImageTypes.decorative} />
                    </IconButton>
                    <Popover
                      id='info-popover'
                      anchorEl={infoPopoverAnchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      open={isInfoModalOpen}
                      onClose={handleInfoPopoverClose}
                      disableRestoreFocus
                      sx={{ pointerEvents: 'none' }}
                    >
                      <Box display='flex' flexDirection='column' alignItems='flex-start' m={3} maxWidth={400}>
                        <Typography variant='h3' mb={2}>
                          {t('assistedApplication.eSignatureDetails.signatureType.infoPopover.mainTitle')}
                        </Typography>
                        <Typography variant='h4'>
                          {t('assistedApplication.eSignatureDetails.signatureType.infoPopover.remoteTitle')}
                        </Typography>
                        <Typography variant='body1' mb={2}>
                          {t('assistedApplication.eSignatureDetails.signatureType.infoPopover.remoteText')}
                        </Typography>
                        <Typography variant='h4'>
                          {t('assistedApplication.eSignatureDetails.signatureType.infoPopover.inPersonTitle')}
                        </Typography>
                        <Typography variant='body1'>
                          {t('assistedApplication.eSignatureDetails.signatureType.infoPopover.inPersonText')}
                        </Typography>
                      </Box>
                    </Popover>
                  </Box>

                  <RadioGroup
                    row
                    value={signatureType}
                    onChange={(e) => onSignatureTypeChange(e.target.value as EsignSignatureType)}
                    sx={{ marginLeft: '30px' }}
                    aria-label={t('assistedApplication.eSignatureDetails.signatureType.title')}
                  >
                    {[EsignSignatureType.remote, EsignSignatureType.inPerson].map((type) => {
                      return (
                        <StyledFormControlLabel
                          key={type}
                          control={<Radio size='small' />}
                          label={t(`assistedApplication.eSignatureDetails.signatureType.${type}`)}
                          value={type}
                          checked={type === signatureType}
                          disabled={!isEditMode}
                        />
                      );
                    })}
                  </RadioGroup>
                </Box>
                {signatureType === EsignSignatureType.inPerson &&
                  !isEditMode &&
                  eSignCeremony?.status !== ESignCeremonyStatus.COMPLETED && (
                    <Box display='flex' justifyContent='space-around' alignItems='center' mt={1}>
                      <InformationBox
                        text={t(`assistedApplication.eSignatureDetails.inPerson.${infoBoxDetails.message}`)}
                        variant={infoBoxDetails.infoVariant}
                      />

                      <StyledSubmitButton
                        isLoading={infoBoxDetails.loading}
                        disabled={infoBoxDetails.disabled}
                        onClick={() => window.open(eSignCeremony?.signingUrl, '_blank')}
                      >
                        {t('assistedApplication.eSignatureDetails.inPerson.signingUrlButton')}
                      </StyledSubmitButton>
                    </Box>
                  )}
              </Fragment>
            )}
            {isEditMode
              ? signers.map((participant, idx) => {
                  return (
                    <Box key={`signer-${participant.partyId}`} mt={2}>
                      <SignerInfoForm
                        disabled={isLoading}
                        participant={participant}
                        onUpdateSigner={(signer) => onUpdateSigner(signer, idx)}
                        signatureType={signatureType}
                      />
                    </Box>
                  );
                })
              : signers.map((participant) => {
                  const signer = getSignerForParticipant(participant, eSignCeremony?.eSignSigners);
                  return signer ? (
                    <Box key={`signer-${participant.partyId}`} mt={2}>
                      <SignerInfo {...getOutcomeForParticipant(participant, pointOfSaleDecisions)} signer={signer} />
                    </Box>
                  ) : null;
                })}
          </Fragment>
        )}
        {tabValue === 1 && (
          <Fragment>
            <Collapse in={isDocumentSignInfoBoxOpen}>
              <Alert onClose={closeInfoBox} severity='info'>
                {t('assistedApplication.signatureDrawer.infoBoxMessage')}
              </Alert>
            </Collapse>

            <Box py={2}>
              {requiredFiles.map((file) => (
                <DocumentToSign
                  key={file.template.id}
                  participants={signers}
                  application={application}
                  template={file.template}
                  storedFile={file.storedFile}
                />
              ))}
            </Box>
          </Fragment>
        )}
      </Box>
    </Box>
  );
}

function getOutcomeForParticipant(
  participant: ESignSigner2FAInfo,
  pointOfSaleDecisions: ApplicationPointOfSaleDecisions | undefined,
): {
  outcome: OutcomeCode | undefined;
  overrideOutcome: string | undefined;
} {
  let outcome = undefined;
  let overrideOutcome = undefined;
  if (pointOfSaleDecisions && participant?.partyId) {
    outcome = pointOfSaleDecisions.decisions[participant.partyId]?.outcome;
    overrideOutcome = pointOfSaleDecisions.decisions[participant.partyId]?.overrideOutcome;
  }
  return { outcome, overrideOutcome };
}
