import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';

import Typography from '../../../../Components/Typography';
import { ReactHtmlParser } from '@breathelife/ui-components';

type SubsectionViewProps = {
  title?: string;
  text?: string;
};

export function SubsectionHeader(props: SubsectionViewProps): ReactElement | null {
  const { title, text } = props;

  return (
    <Fragment>
      {title && (
        <Box mb={3}>
          {title ? (
            <Typography variant='h3' component='h2'>
              <ReactHtmlParser html={title.toUpperCase()} />
            </Typography>
          ) : null}
        </Box>
      )}
      {text && (
        <Box mb={3}>
          <Typography variant='body1'>
            <ReactHtmlParser html={text} />
          </Typography>
        </Box>
      )}
    </Fragment>
  );
}
