import { Fragment, ReactElement, useCallback, useMemo, useState } from 'react';

import { ESignSigner2FAInfo, EsignSignatureType } from '@breathelife/types';

import { getFormValidationError } from '../../../../Helpers/inputValidation/form/signer';
import { useCarrierContext, useDispatch, useNavigation } from '../../../../Hooks';

import { useGetApplicationQuery } from '../../../../ReactQuery/Application/application.queries';
import { notificationSlice } from '../../../../ReduxStore/Notification/NotificationSlice';
import { CancelESignatureModal } from '../../Modals/ESignature/CancelESignatureModal';
import { SendESignatureRequestModal } from '../../Modals/ESignature/SendESignatureRequestModal';
import { ESignatureDetailsView } from './ESignatureDetailsView';
import { useApplicationStoredFilesContext } from '../Documents/ApplicationStoredFilesContext';

type ESignatureDetailsContainerProps = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (signers: ESignSigner2FAInfo[], inPerson: boolean) => void;
};

export function ESignatureDetailsContainer({
  isLoading,
  isOpen,
  onClose,
  onSubmit,
}: ESignatureDetailsContainerProps): ReactElement {
  const { features } = useCarrierContext();
  const { applicationId } = useNavigation();
  const { data: application } = useGetApplicationQuery(applicationId);
  const { requiredFiles } = useApplicationStoredFilesContext();

  const dispatch = useDispatch();

  const [signers, setSigners] = useState<ESignSigner2FAInfo[]>([]);
  const [signatureType, setSignatureType] = useState(EsignSignatureType.remote);
  const [isSendRequestModalOpen, setIsSendRequestModalOpen] = useState(false);
  const [isCancelESignatureModalOpen, setIsCancelESignatureModalOpen] = useState(false);

  const formError = useMemo(
    () => getFormValidationError(signers, signatureType, features.eSignature?.enforceUniqueEmail),
    [signers, features.eSignature?.enforceUniqueEmail, signatureType],
  );

  const onCloseSendRequestModal = useCallback(() => {
    setIsSendRequestModalOpen(false);
  }, []);

  const onUpdateSigner = useCallback((updatedSigner: ESignSigner2FAInfo, updatedSignerIdx: number) => {
    setSigners((signers) =>
      signers.map((signer, idx) => {
        if (idx === updatedSignerIdx) return updatedSigner;
        return signer;
      }),
    );
  }, []);

  const onClickSend = useCallback(() => {
    if (formError) {
      dispatch(
        notificationSlice.actions.setError({
          message: formError.message,
        }),
      );
    } else {
      setIsSendRequestModalOpen(true);
    }
  }, [dispatch, formError]);

  const onSendSignatureRequest = useCallback(async () => {
    setIsSendRequestModalOpen(false);
    const inPerson = signatureType === EsignSignatureType.inPerson;
    onSubmit(signers, inPerson);
  }, [onSubmit, signers, signatureType]);

  const onClickCancel = useCallback(async () => {
    setIsCancelESignatureModalOpen(true);
  }, []);

  const ENABLE_DECOUPLE_ESIGN_FLOW = !!features.enableDecoupleESignFlow?.enabled;

  const onCloseCancelESignatureModal = useCallback(() => {
    setIsCancelESignatureModalOpen(false);

    // Close the ESignature drawer when we cancel the ceremony
    if (ENABLE_DECOUPLE_ESIGN_FLOW) {
      onClose();
    }
  }, [ENABLE_DECOUPLE_ESIGN_FLOW, onClose]);

  return (
    <Fragment>
      <ESignatureDetailsView
        application={application}
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
        onClickSend={onClickSend}
        onClickCancel={onClickCancel}
        onUpdateSigner={onUpdateSigner}
        requiredFiles={requiredFiles}
        signers={signers}
        setSigners={setSigners}
        signatureType={signatureType}
        setSignatureType={setSignatureType}
      />
      <SendESignatureRequestModal
        isOpen={isSendRequestModalOpen}
        onClose={onCloseSendRequestModal}
        onConfirm={onSendSignatureRequest}
        signatureType={signatureType}
      />
      <CancelESignatureModal
        application={application}
        isOpen={isCancelESignatureModalOpen}
        onClose={onCloseCancelESignatureModal}
      />
    </Fragment>
  );
}
