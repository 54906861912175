import dayjs from 'dayjs';

import {
  AgentLicenseProperties,
  AgentLicenseVerificationStatus,
  NodeIdInstanceScope,
  YesNoValue,
} from '@breathelife/types';

import { PlatformFeatures } from '../../Models/PlatformFeatures';

export function validateAgentLicense(
  provinceOrState: string,
  licensesData: AgentLicenseProperties[],
): AgentLicenseVerificationStatus {
  if (!provinceOrState) {
    return AgentLicenseVerificationStatus.neutral;
  }
  const agentLicense = licensesData.find((licenses) => licenses.jurisdiction === provinceOrState);
  if (!agentLicense) {
    return AgentLicenseVerificationStatus.invalidJurisdiction;
  }

  if (agentLicense) {
    const { expirationDate } = agentLicense;

    if (dayjs(expirationDate).isBefore(dayjs())) {
      return AgentLicenseVerificationStatus.expired;
    }

    return AgentLicenseVerificationStatus.valid;
  }

  return AgentLicenseVerificationStatus.invalidJurisdiction;
}

export function refreshLicenseValidation(
  provincesOrStates: string[] | null,
  licensesData: AgentLicenseProperties[],
): AgentLicenseVerificationStatus {
  if (!provincesOrStates?.length || !licensesData.length) {
    return AgentLicenseVerificationStatus.neutral;
  }

  const verificationStatuses = provincesOrStates.map((provinceOrState) => {
    return validateAgentLicense(provinceOrState, licensesData);
  });

  if (verificationStatuses.some((licenseValidity) => licenseValidity === AgentLicenseVerificationStatus.expired)) {
    return AgentLicenseVerificationStatus.expired;
  }

  if (
    verificationStatuses.some(
      (licenseValidity) => licenseValidity === AgentLicenseVerificationStatus.invalidJurisdiction,
    )
  ) {
    return AgentLicenseVerificationStatus.invalidJurisdiction;
  }

  return AgentLicenseVerificationStatus.valid;
}

export function getOwnerProvincesOrStates(
  features: PlatformFeatures,
  getAnswer: (nodeId: string, nodeIdScope?: NodeIdInstanceScope) => unknown,
): string[] {
  if (!features.assistedApplication?.nodeIdAgentLicenseConfig) {
    throw new Error('Agent License node ID configuration is missing');
  }

  const provincesOrStates = [];

  const {
    ownerSameAsInsuredNodeIds,
    ownerProvinceOrStateNodeIds,
    ownerResidenceAddressSameAsInsuredNodeIds,
    insuredProvinceOrStateNodeIds,
  } = features.assistedApplication.nodeIdAgentLicenseConfig;

  const areSomeOwnersSameAsInsured = ownerSameAsInsuredNodeIds.reduce((acc, nodeId) => {
    if (getAnswer(nodeId) === YesNoValue.yes) {
      acc = true;
    }
    return acc;
  }, false);

  const areSomeOwnersSameAddressAsInsured = ownerResidenceAddressSameAsInsuredNodeIds.reduce((acc, nodeId) => {
    if (getAnswer(nodeId) === YesNoValue.yes) {
      acc = true;
    }
    return acc;
  }, false);

  if (areSomeOwnersSameAsInsured || areSomeOwnersSameAddressAsInsured) {
    const insuredProvinceOrStateAnswers = insuredProvinceOrStateNodeIds
      .map((nodeId) => {
        return getAnswer(nodeId) as string;
      })
      .filter((result) => result !== undefined);

    if (insuredProvinceOrStateAnswers.length) provincesOrStates.push(...insuredProvinceOrStateAnswers);
  }

  const ownerProvinceOrStateAnswers = ownerProvinceOrStateNodeIds
    .map((nodeId) => {
      return getAnswer(nodeId) as string;
    })
    .filter((result) => result !== undefined);

  if (ownerProvinceOrStateAnswers.length) provincesOrStates.push(...ownerProvinceOrStateAnswers);

  return provincesOrStates.length ? provincesOrStates : [];
}
