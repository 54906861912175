import {
  Localized,
  NodeIdToAnswerPathMap,
  PivotAnswerResolver,
  QuestionnaireDefinition,
  QuestionnaireEngine,
} from '@breathelife/questionnaire-engine';
import {
  DEFAULT_TIMEZONE_NAME,
  InsuranceModule,
  QuestionnaireBlueprint,
  Timezone,
  VersionedAnswers,
} from '@breathelife/types';

import { getQuestionnaireEngineConfig } from '../../Helpers/questionnaireEngineConfigs';
import { Application } from '../../Models/Application';
import { CarrierQuestionnaireResponse } from '../../Services/CarrierQuestionnaireService';
import { PlatformFeatures } from '../../Models/PlatformFeatures';
import { logger } from '@breathelife/monitoring-frontend';

export type ApplicationProgressProps = {
  application?: Application;
  needsAnalysisOnly?: boolean;
  questionnaireResponse?: CarrierQuestionnaireResponse;
};

type ApplicationProgressByModuleProps = {
  application?: Application;
  insuranceModule: InsuranceModule;
  nodeIdToAnswerPathMap?: NodeIdToAnswerPathMap;
  questionnaire?: Localized<QuestionnaireDefinition>;
  blueprint?: QuestionnaireBlueprint;
  features: PlatformFeatures;
};

export function getApplicationProgressByModule(props: ApplicationProgressByModuleProps): number {
  const { application, insuranceModule, nodeIdToAnswerPathMap, questionnaire, blueprint, features } = props;
  // const { t } = useTranslation();

  if (!application || !nodeIdToAnswerPathMap || !questionnaire || !blueprint) return 0;

  const timezoneResult = Timezone.from(application.timezone || DEFAULT_TIMEZONE_NAME);
  if (timezoneResult.success === false) {
    return 0;
  }

  const answerResolver = PivotAnswerResolver.from(
    new VersionedAnswers({ v1: application.answers, v2: application.answersV2 }),
    nodeIdToAnswerPathMap,
    blueprint,
    'v1',
    logger,
  );

  const questionnaireEngine = QuestionnaireEngine.from({
    questionnaire,
    answerResolver,
    context: {
      insuranceModules: [insuranceModule],
    },
    config: getQuestionnaireEngineConfig(application.mode, features),
    timezone: timezoneResult.value,
    applicationContext: application.applicationContext,
    text: (key: string) => key,
    currentDateOverride: application.submissionDate?.toString() || null,
  });

  if (!questionnaireEngine) return 0;

  const isCompleted =
    insuranceModule === InsuranceModule.insuranceApplication ? application.signed || application.submitted : undefined;

  return questionnaireEngine.calculateProgress(isCompleted);
}
