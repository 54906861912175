import {
  CreateApplicationPayload,
  TotalPremiums,
  VersionedAnswers,
  Answers,
  ComprehensivePricingAPIReturnValue,
  LineOfBusinessName,
  ISO_8601_String,
} from '@breathelife/types';

import { Application } from '../Models/Application';
import ApiService from './ApiService';

export type LaunchAssistedApplicationPayload = Partial<Pick<Application, 'coverageAmount' | 'product'>>;
export type UpdateAssistedApplicationPayload = Required<
  Pick<Application, 'answers' | 'answersV2' | 'answersUpdatedAt'>
>;

export async function launchNewAssistedApplication(data: CreateApplicationPayload): Promise<Application> {
  const response = await ApiService.launchNewAssistedApplication<Application>(data);
  return response.data;
}

export async function launchAssistedApplication(
  applicationId: string,
  data: LaunchAssistedApplicationPayload,
): Promise<Application> {
  const response = await ApiService.launchAssistedApplication<Application>(applicationId, data);
  return response.data;
}

export async function updateAssistedApplicationAnswers(
  applicationId: string,
  data: UpdateAssistedApplicationPayload,
): Promise<{ answersUpdatedAt: ISO_8601_String }> {
  return ApiService.updateAssistedApplicationAnswers(applicationId, data);
}

export async function getProductsWidgetTotalPremiums(
  applicationId: string,
  answers: VersionedAnswers,
): Promise<TotalPremiums | null> {
  return ApiService.getProductsWidgetTotalPremiums(applicationId, answers.v1, answers.v2);
}

export async function getComprehensivePricing(
  applicationId: string,
  answers: Answers,
  answersV2: Answers,
  lineOfBusinessName: LineOfBusinessName,
): Promise<ComprehensivePricingAPIReturnValue> {
  return ApiService.getComprehensivePricing(applicationId, answers, answersV2, lineOfBusinessName);
}
