import { AnswersChangedSubscriber } from '@breathelife/questionnaire-engine';
import ApiService from '../../../Services/ApiService';
import { notificationSlice } from '../../../ReduxStore/Notification/NotificationSlice';
import { PlatformFeatures } from '../../../Models/PlatformFeatures';
import { Dispatch } from '../../../ReduxStore/types';
import { TFunction } from 'i18next';
import { JetDecisionOutcomes } from '@breathelife/types';

export const createJetSubscriber = (
  applicationId: string,
  setIsFetching: (value: boolean) => void,
  setJetDecisionOutcomes: (jetDecisionOutcomes: JetDecisionOutcomes[]) => void,
  features: PlatformFeatures,
  dispatch: Dispatch,
  t: TFunction,
): AnswersChangedSubscriber => {
  return async (questionnaireEngine) => {
    if (features.assistedApplication?.jetDecisionWidget?.enabled && !!applicationId) {
      setIsFetching(true);
      const answers = questionnaireEngine.getAnswerResolverInstance().dump();

      try {
        const jetDecisionOutcomes = await ApiService.getJetDecisionOutcomes(applicationId, answers.v1, answers.v2);
        setJetDecisionOutcomes(jetDecisionOutcomes);
      } catch (e: unknown) {
        setJetDecisionOutcomes([]);
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToLoadJetDecisionOutcomes'),
          }),
        );
      } finally {
        setIsFetching(false);
      }
    }
  };
};
