import { PropsWithChildren, ReactElement, createContext, useContext, useMemo } from 'react';
import { RequiredFile, useApplicationStoredFiles } from '../../../../Hooks';
import { StoredFileWithExistsProperty } from '@breathelife/types';
import { Application } from '../../../../Models/Application';
import { FileSizeUnits, convertFileSize } from '../../../../Helpers/fileSize';
import { useQuestionnaireEngine } from '../../../../Hooks/useQuestionnaireEngine';

type ApplicationStoredFilesContextValue = {
  isMissingRequiredFiles: boolean;
  someFilesDoNotExist: boolean;
  requiredFiles: RequiredFile[];
  otherFiles: StoredFileWithExistsProperty[];
  totalFileSize: number;
  isMaxTotalFileSizeReached: boolean;
  hasIssueWithDocuments: boolean;
};

const ApplicationStoredFilesContext = createContext<ApplicationStoredFilesContextValue>({
  isMissingRequiredFiles: false,
  someFilesDoNotExist: false,
  requiredFiles: [],
  otherFiles: [],
  totalFileSize: 0,
  isMaxTotalFileSizeReached: false,
  hasIssueWithDocuments: false,
});

export function useApplicationStoredFilesContext(): ApplicationStoredFilesContextValue {
  return useContext(ApplicationStoredFilesContext);
}

// Maximum total file size for all uploaded files
export const MAX_TOTAL_FILE_SIZE_MB = 20;
export const MAX_TOTAL_FILE_SIZE_B = convertFileSize(MAX_TOTAL_FILE_SIZE_MB, FileSizeUnits.MB, FileSizeUnits.B);

export function ApplicationStoredFilesContextProvider(
  props: PropsWithChildren<{
    application: Application;
  }>,
): ReactElement {
  const { questionnaireEngine } = useQuestionnaireEngine();
  const [requiredFiles, otherFiles] = useApplicationStoredFiles(
    props.application,
    questionnaireEngine.getAnswerResolverInstance(),
  );

  const isMissingRequiredFiles = useMemo(() => {
    return (
      props.application.mode !== 'paper' && requiredFiles.some((file) => !file.storedFile && file.template.isRequired)
    );
  }, [requiredFiles, props.application.mode]);

  const someFilesDoNotExist = useMemo(() => {
    return (
      otherFiles.some((file) => !file.exists) ||
      requiredFiles.some((requiredFile) => requiredFile.storedFile && !requiredFile.storedFile.exists)
    );
  }, [requiredFiles, otherFiles]);

  const totalFileSize = useMemo(() => {
    const requiredSize = requiredFiles.reduce((acc, curr) => acc + (curr.storedFile?.sizeBytes ?? 0), 0);
    const otherSize = otherFiles.reduce((acc, curr) => acc + (curr.sizeBytes ?? 0), 0);
    return requiredSize + otherSize;
  }, [requiredFiles, otherFiles]);

  const isMaxTotalFileSizeReached = totalFileSize > MAX_TOTAL_FILE_SIZE_B;
  const hasIssueWithDocuments = isMissingRequiredFiles || someFilesDoNotExist || isMaxTotalFileSizeReached;

  return (
    <ApplicationStoredFilesContext.Provider
      value={{
        isMissingRequiredFiles,
        someFilesDoNotExist,
        requiredFiles,
        otherFiles,
        totalFileSize,
        isMaxTotalFileSizeReached,
        hasIssueWithDocuments,
      }}
    >
      {props.children}
    </ApplicationStoredFilesContext.Provider>
  );
}
