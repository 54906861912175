import { ReactElement, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '../../../../Styles/themed-styled-components';

import { Input as TextInput, Box, Separator, ReactHtmlParser } from '@breathelife/ui-components';

import { Button } from '../../../../Components/Button/Button';
import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import Typography from '../../../../Components/Typography';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import {
  useNewDraftQuestionnaireVersionMutation,
  usePublishQuestionnaireVersionMutation,
} from '../../../../ReactQuery/Admin/Questionnaire/questionnaireVersion.mutations';
import { generateQuestionnaireVersionUrl } from '../../../../Navigation/Urls';
import { PublishQuestionnaireVersionResponse } from '@breathelife/types';

const PublishButton = styled(Button)`
  padding: 8px 12px;
`;

type Props = {
  isDraft: boolean;
  isLatestVersion: boolean;
  description: string;
  disabled: boolean;
};

export function CreateDraftOrPublish({ isDraft, isLatestVersion, disabled, description }: Props): ReactElement | null {
  if (!isDraft && !isLatestVersion) {
    // Drafts can only be created at the latest version, if it is not already a draft.
    return null;
  }

  return isDraft ? (
    <PublishQuestionnaire disabled={disabled} existingDescription={description} />
  ) : (
    <CreateDraftQuestionnaire disabled={disabled} />
  );
}

function CreateDraftQuestionnaire(props: { disabled: boolean }): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [description, setDescription] = useState('');

  const newDraftQuestionnaireVersionMutation = useNewDraftQuestionnaireVersionMutation();

  const onModalClose = (): void => {
    if (newDraftQuestionnaireVersionMutation.isLoading) return;
    setDescription('');
    setIsOpen(false);
  };

  const onCreateDraft = (): void => {
    newDraftQuestionnaireVersionMutation.mutate(
      { description },
      {
        onSuccess: (draft) => {
          if (draft.questionnaireVersionId) {
            navigate(generateQuestionnaireVersionUrl(draft.questionnaireVersionId));
          }
        },
      },
    );
  };

  return (
    <Fragment>
      <Button color='primary' variant='contained' onClick={() => setIsOpen(true)} disabled={props.disabled}>
        {t('admin.questionnaireManagement.newDraft')}
      </Button>
      {isOpen && (
        <ModalLayout
          maxWidth='md'
          isOpen={true}
          closeModal={onModalClose}
          title={t('admin.questionnaireManagement.createDraftQuestionnaire.modalTitle')}
          submitButton={
            <SubmitButton
              data-testid='questionnaire-editor-publish-questionnaire'
              disabled={props.disabled}
              isLoading={newDraftQuestionnaireVersionMutation.isLoading}
              onClick={onCreateDraft}
            >
              {t('admin.questionnaireManagement.createDraft')}
            </SubmitButton>
          }
        >
          <Box pb={2}>
            <Typography variant='body1'>
              <ReactHtmlParser html={t('admin.questionnaireManagement.createDraftQuestionnaire.modalDetails')} />
            </Typography>
          </Box>
          <TextInput
            inputVariant='outlined'
            label={t('admin.questionnaireManagement.description')}
            value={description}
            onChange={(event) => {
              const value = event.target.value;
              setDescription(value);
            }}
          />
        </ModalLayout>
      )}
    </Fragment>
  );
}

function PublishQuestionnaire(props: { existingDescription: string; disabled: boolean }): ReactElement {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<{ type: string; message: string } | null>(null);
  const [description, setDescription] = useState(props.existingDescription);

  const publishQuestionnaireVersionMutation = usePublishQuestionnaireVersionMutation();

  const onModalClose = (): void => {
    if (publishQuestionnaireVersionMutation.isLoading) return;

    setDescription('');
    setError(null);
    setIsOpen(false);
  };

  const onSuccess = (response: PublishQuestionnaireVersionResponse): void => {
    if (response.tag === 'success') {
      onModalClose();
    } else {
      setError({ type: response.type, message: response.message });
    }
  };

  const onPublish = (): void => {
    publishQuestionnaireVersionMutation.mutate({ description }, { onSuccess: onSuccess });
  };

  return (
    <Fragment>
      <PublishButton
        color='primary'
        variant='outlined'
        data-testid='questionnaire-editor-publish-button'
        onClick={() => setIsOpen(true)}
        disabled={props.disabled}
      >
        {t('admin.questionnaireManagement.publish')}
      </PublishButton>
      {isOpen && (
        <ModalLayout
          maxWidth='md'
          isOpen={true}
          closeModal={onModalClose}
          title={t('admin.questionnaireManagement.publishQuestionnaire.modalTitle')}
          submitButton={
            <SubmitButton
              disabled={props.disabled || !description}
              isLoading={publishQuestionnaireVersionMutation.isLoading}
              onClick={onPublish}
              data-testid='questionnaire-editor-publish-questionnaire'
            >
              {t('admin.questionnaireManagement.publish')}
            </SubmitButton>
          }
        >
          <Box pb={2}>
            <Typography variant='body1'>
              <ReactHtmlParser html={t('admin.questionnaireManagement.publishQuestionnaire.modalDetails')} />
            </Typography>
          </Box>
          <TextInput
            required
            inputVariant='outlined'
            inputProps={{
              ['data-testid']: 'questionnaire-version-description',
            }}
            label={t('admin.questionnaireManagement.description')}
            value={description}
            onChange={(event) => {
              const value = event.target.value;
              setDescription(value);
            }}
          />
          {error && (
            <>
              <Separator />
              <Typography variant='h3' color='red'>
                Error related to the {error.type}.
              </Typography>
              <Typography variant='body1' grey={90} style={{ 'word-break': 'break-all' }}>
                {error.message}
              </Typography>
            </>
          )}
        </ModalLayout>
      )}
    </Fragment>
  );
}
