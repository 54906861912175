import { PropsWithChildren, ReactElement, createContext, useCallback, useContext, useState } from 'react';

type ModalsContext = {
  isSaveAnswersValidationModalOpen: boolean;
  openSaveAnswersValidationModal: () => void;
  closeSaveAnswersValidationModal: () => void;
};

export const context = createContext<ModalsContext | undefined>(undefined);

export const useModals = (): ModalsContext => {
  const modalsContext = useContext(context);

  if (!modalsContext) {
    throw new Error('useModals is not wrapped under a ModalsContextProvider.');
  }

  return modalsContext;
};

export function ModalsContextProvider(props: PropsWithChildren): ReactElement | null {
  const [isSaveAnswersValidationModalOpen, setSaveAnswersValidationModalOpen] = useState<boolean>(false);

  const openSaveAnswersValidationModal = useCallback(() => {
    setSaveAnswersValidationModalOpen(true);
  }, [setSaveAnswersValidationModalOpen]);

  const closeSaveAnswersValidationModal = useCallback(() => {
    setSaveAnswersValidationModalOpen(true);
  }, [setSaveAnswersValidationModalOpen]);

  return (
    <context.Provider
      value={{ isSaveAnswersValidationModalOpen, openSaveAnswersValidationModal, closeSaveAnswersValidationModal }}
    >
      {props.children}
    </context.Provider>
  );
}
