import {
  LocalizedQuestionnaire,
  NodeIdToAnswerPathMap,
  RepeatableOptionsWithLimits,
  deserializeNodeIdToAnswerPathMap,
} from '@breathelife/questionnaire-engine';
import { QuestionnaireBlueprint, QuestionnaireVersionPricingNodeIds } from '@breathelife/types';
import { PropsWithChildren, ReactElement, createContext, useMemo } from 'react';
import { useCarrierContext } from '../Hooks';
import { useFetchCarrierQuestionnaireQuery } from '../ReactQuery/CarrierQuestionnaires/carrierQuestionnaire.queries';
import { useFetchPricingFieldIdentifiersQuery } from '../ReactQuery/Admin/PricingFieldIdentifiers/pricingFieldIdentifiers.queries';
import { SectionGroupId } from '../Helpers/questionnaireAnswers';

export type AssistedApplicationQuestionnaireVersionValue = {
  maxNumberOfInsured: number;
  isSectionGroupsTabsEnabled: boolean;
  pricingFieldIdentifiers?: QuestionnaireVersionPricingNodeIds;
  questionnaire: LocalizedQuestionnaire;
  blueprint: QuestionnaireBlueprint;
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap;
};

export const AssistedApplicationQuestionnaireVersionContext = createContext<
  AssistedApplicationQuestionnaireVersionValue | undefined
>(undefined);

type AssistedApplicationQuestionnaireVersionContextProviderProps = {
  applicationId: string;
};

export function AssistedApplicationQuestionnaireVersionContextProvider(
  props: PropsWithChildren<AssistedApplicationQuestionnaireVersionContextProviderProps>,
): ReactElement | null {
  const { applicationId } = props;

  const { features } = useCarrierContext();

  const { data: questionnaireData } = useFetchCarrierQuestionnaireQuery(applicationId, {});

  const { data: questionnaireVersionFieldIdentifiersData } = useFetchPricingFieldIdentifiersQuery(
    questionnaireData?.versionId,
    { enabled: features.pricingCalculation.enabled && questionnaireData?.versionId != undefined },
  );

  const nodeIdToAnswerPathMap = useMemo(() => {
    if (!questionnaireData?.nodeIdToAnswerPath) return;

    return deserializeNodeIdToAnswerPathMap(questionnaireData?.nodeIdToAnswerPath);
  }, [questionnaireData?.nodeIdToAnswerPath]);

  if (
    !questionnaireData ||
    !nodeIdToAnswerPathMap ||
    !questionnaireData.blueprint ||
    (features.pricingCalculation.enabled && !questionnaireVersionFieldIdentifiersData)
  ) {
    return null;
  }

  const insuredPeopleSectionGroupOptions = questionnaireData.questionnaire?.find(
    (sectionGroup) => sectionGroup.id === SectionGroupId.insuredPeople,
  )?.options as RepeatableOptionsWithLimits;

  return (
    <AssistedApplicationQuestionnaireVersionContext.Provider
      value={{
        // TODO: FIXME: See HOT-3512. We want to make component underneath the provider to use function exposed from the
        // questionnaire engine directly instead of using the subtilities of the questionnaire engine like the Answer Resolver.
        nodeIdToAnswerPathMap: nodeIdToAnswerPathMap,
        maxNumberOfInsured: insuredPeopleSectionGroupOptions?.maxRepetitions,
        isSectionGroupsTabsEnabled: questionnaireData.questionnaire.length > 1,
        pricingFieldIdentifiers: questionnaireVersionFieldIdentifiersData?.[0],
        questionnaire: questionnaireData.questionnaire,
        blueprint: questionnaireData.blueprint,
      }}
    >
      {props.children}
    </AssistedApplicationQuestionnaireVersionContext.Provider>
  );
}
