import { Box } from '@breathelife/mui';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ReactElement, ChangeEvent, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import styled from '../../../../Styles/themed-styled-components';

import { Permission, User, UserFields, UserRowData, UserStatus } from '@breathelife/types';
// Not using UserRole from @breathelife/types because it is also includes service role..
import { UserRole } from '../../../../Services/Auth0';
import { Select } from '../../../../Components/Select/Select';

import { UserStatusBadge } from '../../../../Components/Badge/UserStatusBadge';
import { Image } from '../../../../Components/Images/Image';
import LoadingView from '../../../../Components/LoadingView/LoadingView';
import { Pagination } from '../../../../Components/Pagination/Pagination';
import { Table } from '../../../../Components/Table/Table';
import { SelectOptions, getOptionsFromEnum } from '../../../../Helpers/options';
import { userHasPermission } from '../../../../Helpers/user';
import { useCarrierContext, useSelector } from '../../../../Hooks';

import { MoreActions } from './MoreActions';
import { useUsersContext } from '../UsersContextProvider';
import { useUpdateUserMutation } from '../../../../ReactQuery/Admin/Users/users.mutations';

const StyledSelect = styled(Select)`
  max-width: 180px;
  .MuiInputBase-root {
    height: 24px;
  }
  .MuiSelect-select {
    &:focus {
      background-color: unset !important;
    }
  }
`;

type RoleCellProps = {
  auth0Id: User['auth0Id'];
  isSso: User['isSso'];
  value: UserRole;
  rolesOptions: SelectOptions<UserRole>;
  ssoProfileFields: UserFields;
};

function RoleCell(props: RoleCellProps): ReactElement {
  const updateUserMutation = useUpdateUserMutation(props.auth0Id);
  return (
    <StyledSelect
      isSearchFilter
      id='user_table_role'
      onChange={(value: any, event: ChangeEvent<{ value: unknown }>) => {
        event.stopPropagation();
        updateUserMutation.mutate({ roles: [value as UserRole] });
      }}
      disabled={props.isSso && props.ssoProfileFields.includes('roles')}
      value={props.value}
      options={props.rolesOptions}
    />
  );
}

export function UsersTable(): ReactElement {
  const { t } = useTranslation();
  const { ssoProfileFields } = useCarrierContext();
  const currentUserPermissions = useSelector((store) => store.leadPlatform.authentication?.user?.permissions);
  const rolesOptions = getOptionsFromEnum(UserRole, 'user.role');
  const {
    onOpenDeleteUserConfirmationModal,
    onOpenResendInvitationModal,
    onTableRowClick,
    users,
    onSortingChanged: onSortingChangeProp,
    isLoading,
    total,
    currentPage,
    perPage,
    perPageOptions,
    onPageChange,
    onPerPageChange,
  } = useUsersContext();

  if (!userHasPermission(currentUserPermissions, Permission.UserCreateSuperAdmin)) {
    _.remove(rolesOptions, (option) => option.value === UserRole.superAdmin);
  }

  const tableColumns: Column<UserRowData>[] = useMemo(
    () =>
      getTableColumns({
        onOpenDeleteUserConfirmationModal,
        onOpenResendInvitationModal,
        t,
        ssoProfileFields,
        rolesOptions,
      }),
    [onOpenDeleteUserConfirmationModal, onOpenResendInvitationModal, t, ssoProfileFields, rolesOptions],
  );

  // Set the fullName of users
  const data = _.isEmpty(users)
    ? []
    : users.map(({ firstName, lastName, ...restData }) => {
        return {
          fullName: `${firstName} ${lastName}`,
          ...restData,
        };
      });

  const onSortingChanged = useCallback(
    (columnKey: keyof UserRowData, direction: 'asc' | 'desc') => {
      if (!columnKey || !direction) return;
      onSortingChangeProp({ field: columnKey as keyof User, direction });
    },
    [onSortingChangeProp],
  );

  const handleTableRowClick = useCallback(
    (userId: string) => {
      const selectedUser = users.find((user) => user.id === userId);
      onTableRowClick(selectedUser);
    },
    [users, onTableRowClick],
  );

  if (isLoading && _.isEmpty(users)) return <LoadingView />;
  return (
    <Fragment>
      <Table<UserRowData>
        onRowClick={handleTableRowClick}
        columns={tableColumns}
        data={data}
        onOrderChange={onSortingChanged}
      />
      <Box px={2.5}>
        <Pagination
          total={total || 0}
          page={currentPage}
          perPage={perPage}
          perPageOptions={perPageOptions}
          onPageChange={onPageChange}
          onPerPageChange={onPerPageChange}
        />
      </Box>
    </Fragment>
  );
}

function getTableColumns({
  onOpenResendInvitationModal,
  onOpenDeleteUserConfirmationModal,
  t,
  ssoProfileFields,
  rolesOptions,
}: {
  onOpenResendInvitationModal: (user: User) => void;
  onOpenDeleteUserConfirmationModal: (user: User) => void;
  t: any;
  ssoProfileFields: UserFields;
  rolesOptions: any;
}): Column<UserRowData>[] {
  return [
    {
      Header: '',
      accessor: 'picture',
      Cell: function ({ cell: { value } }): ReactElement | null {
        return value ? <Image src={value} height={32} width={32} radius={50} /> : null;
      },
      disableSortBy: true,
    },
    {
      Header: t('usersListTable.fullName'),
      accessor: 'fullName',
    },
    {
      Header: t('usersListTable.email'),
      accessor: 'emailLogin',
    },
    {
      Header: t('usersListTable.role'),
      accessor: 'roles',
      Cell: function ({ cell: { value, row } }): ReactElement {
        return (
          <RoleCell
            value={(value?.[0] as UserRole) ?? ''}
            auth0Id={row.original.auth0Id}
            isSso={row.original.isSso}
            ssoProfileFields={ssoProfileFields}
            rolesOptions={rolesOptions}
          />
        );
      },
    },
    {
      Header: t('usersListTable.leadsAssigned'),
      accessor: 'leadsCount',
      Cell: function ({ cell: { value } }): ReactElement {
        return <Fragment>{value ?? 0}</Fragment>;
      },
      // Remove this once we are able to sort by computed fields
      // DEV-4869 https://breathelife.atlassian.net/browse/DEV-4869
      disableSortBy: true,
    },
    {
      Header: t('usersListTable.lastConnection'),
      accessor: 'lastLogin',
      Cell: function ({ cell: { value } }): ReactElement {
        return <Fragment>{value ? dayjs(value).format('D MMMM YYYY HH:MM A') : ''}</Fragment>;
      },
    },
    {
      Header: t('usersListTable.status'),
      accessor: 'status',
      Cell: function ({ cell: { value } }: { cell: { value: UserStatus } }): ReactElement {
        return value ? <UserStatusBadge status={value} /> : <></>;
      },
    },
    {
      Header: '',
      accessor: 'id',
      Cell: function ({ cell: { row } }: { cell: { row: { original: User } } }): ReactElement {
        return (
          <MoreActions
            onOpenResendInvitationModal={onOpenResendInvitationModal}
            user={row.original}
            onOpenDeleteUserConfirmationModal={onOpenDeleteUserConfirmationModal}
          />
        );
      },
    },
  ];
}
