import { PropsWithChildren, ReactElement, createContext, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import {
  AnswersChangedSubscriber,
  PivotAnswerResolver,
  QuestionnaireEngine,
  RenderingQuestionnaire,
} from '@breathelife/questionnaire-engine';
import {
  Application,
  DEFAULT_TIMEZONE_NAME,
  InsuranceModule,
  Language,
  PlatformType,
  Timezone,
  VersionedAnswers,
} from '@breathelife/types';
import { logger } from '@breathelife/monitoring-frontend';

import { useAAQuestionnaireVersionContext } from './useAAQuestionnaireVersionContext';
import { getQuestionnaireEngineConfig } from '../Helpers/questionnaireEngineConfigs';
import { useCarrierContext } from './useCarrierContext';
import { sendAnswersToBackend } from '../Components/AssistedApplication/Subscribers/saveAnswersSubscriber';
import { useDispatch } from './useDispatch';
import { createComprehensivePricingSubscriber } from '../Components/AssistedApplication/Subscribers/comprehensivePricingSubscriber';
import { fetchQuotesAndMaybeProducts } from '../Components/AssistedApplication/Subscribers/fetchQuotesSubscriber';
import { useFetchQuotesMutation } from '../ReactQuery/AssistedApplication/assistedApplication.mutations';
import { useModals } from '../Components/AssistedApplication/ModalsContext';
import { createJetSubscriber } from '../Components/AssistedApplication/Subscribers/jetSubscriber';
import { useJetDecisionOutcomes } from './useJetDecisionOutcomes';

export type AssistedApplicationQuestionnaireVersionValue = {
  questionnaireEngine: QuestionnaireEngine;
  renderingQuestionnaire: RenderingQuestionnaire;
  setRenderingQuestionnaire: (rq: RenderingQuestionnaire) => void;
  hasActiveSubscribers: boolean;
};

export const QuestionnaireEngineContext = createContext<AssistedApplicationQuestionnaireVersionValue | undefined>(
  undefined,
);

export function useQuestionnaireEngine(): AssistedApplicationQuestionnaireVersionValue {
  const context = useContext(QuestionnaireEngineContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useQuestionnaireEngine must be used within an AssistedApplicationQuestionnaireVersionContextProvider',
    );
  }

  return context;
}

type Props = PropsWithChildren<{
  application: Application;
}>;

export function QuestionnaireEngineProvider(props: Props): ReactElement | null {
  const { application } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { nodeIdToAnswerPathMap, blueprint, questionnaire } = useAAQuestionnaireVersionContext();
  const { features } = useCarrierContext();
  const { openSaveAnswersValidationModal } = useModals();
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();
  const { pricingFieldIdentifiers } = useAAQuestionnaireVersionContext();
  const { setIsFetching, setJetDecisionOutcomes } = useJetDecisionOutcomes();
  const fetchQuotesMutation = useFetchQuotesMutation();

  const [renderingQuestionnaire, setRenderingQuestionnaire] = useState<RenderingQuestionnaire>([]);

  const [isQuotesSubscriberActive, setIsQuotesSubscriberActive] = useState<boolean>(false);
  const [isPricingSubscriberActive, setIsPricingSubscriberActive] = useState<boolean>(false);
  const [isAnswersSubscriberActive, setIsAnswersSubscriberActive] = useState<boolean>(false);

  const hasActiveSubscribers = useMemo(() => {
    return isQuotesSubscriberActive || isPricingSubscriberActive || isAnswersSubscriberActive;
  }, [isQuotesSubscriberActive, isPricingSubscriberActive, isAnswersSubscriberActive]);

  const questionnaireEngine = useMemo(() => {
    const timezoneResult = Timezone.from(application.timezone || DEFAULT_TIMEZONE_NAME);
    if (timezoneResult.success === false) {
      throw new Error('Could not create the timezone for the application or using the default one.');
    }

    const answerResolver = PivotAnswerResolver.from(
      new VersionedAnswers({ v1: application.answers, v2: application.answersV2 }),
      nodeIdToAnswerPathMap,
      blueprint,
      'v1',
      logger,
    );

    // There are performance implications to this object being remade (some internal data structures get initialized when updating answers that are expensive to rebuild)
    // Once the questionnaire and nodeIdToAnswerPathMap are loaded remaking this object should be avoided.
    const subscribers: AnswersChangedSubscriber[] = [
      sendAnswersToBackend(application, openSaveAnswersValidationModal, setIsAnswersSubscriberActive, dispatch, t),
      fetchQuotesAndMaybeProducts(
        application,
        dispatch,
        queryClient,
        i18n.language as Language,
        features,
        pricingFieldIdentifiers,
        fetchQuotesMutation,
        setIsQuotesSubscriberActive,
      ),
      createComprehensivePricingSubscriber(
        features,
        application,
        pricingFieldIdentifiers,
        dispatch,
        t,
        setIsPricingSubscriberActive,
      ),
      createJetSubscriber(application.id, setIsFetching, setJetDecisionOutcomes, features, dispatch, t),
    ];

    return QuestionnaireEngine.from({
      questionnaire: questionnaire,
      answerResolver,
      context: {
        platformTypes: [PlatformType.producer],
        insuranceModules: [InsuranceModule.insuranceApplication],
        applicationModes: [application.mode],
      },
      config: getQuestionnaireEngineConfig(application.mode, features),
      timezone: timezoneResult.value,
      additionalTextProcessing: t,
      applicationContext: application.applicationContext,
      onRenderingQuestionnaireChanged: setRenderingQuestionnaire,
      answerChangedSubscribers: subscribers,
      text: t,
      currentDateOverride: application.submissionDate?.toString() || null,
    });
  }, [
    application,
    nodeIdToAnswerPathMap,
    blueprint,
    questionnaire,
    features,
    t,
    dispatch,
    openSaveAnswersValidationModal,
  ]);

  return (
    <QuestionnaireEngineContext.Provider
      value={{
        questionnaireEngine,
        renderingQuestionnaire,
        setRenderingQuestionnaire,
        hasActiveSubscribers,
      }}
    >
      {props.children}
    </QuestionnaireEngineContext.Provider>
  );
}
