import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Radio, RadioGroup } from '@breathelife/mui';
import { ESignSigner2FAInfo, ParticipantRoles, ESignSignerAuthMethod, EsignSignatureType } from '@breathelife/types';
import { ImageTypes, Input as TextInput, PhoneInput } from '@breathelife/ui-components';

import styled from '../../../../../Styles/themed-styled-components';
import Typography from '../../../../../Components/Typography';
import { Icon } from '../../../../../Components/Icons';
import { getFieldValidationError, SignerFormField } from '../../../../../Helpers/inputValidation/form/signer';
import { SignerInfoHeader } from './SignerInfoHeader';
import { StyledFormControlLabel } from '../../../../../Components/Checkbox/Styles';
import { useCarrierContext } from '../../../../../Hooks';

type SignerInfoFormProps = {
  disabled: boolean;
  participant: ESignSigner2FAInfo;
  onUpdateSigner: (signer: ESignSigner2FAInfo) => void;
  signatureType: EsignSignatureType;
};

const AuthMethodContainer = styled.div`
  display: flex;
  align-items: center;

  * {
    min-width: fit-content;
  }
`;

const StyledTextInput = styled(TextInput)`
  span {
    font-size: 14px !important;
  }
`;

export function SignerInfoForm(props: SignerInfoFormProps): ReactElement {
  const { disabled, participant, onUpdateSigner, signatureType } = props;

  const { t } = useTranslation();
  const { features } = useCarrierContext();
  const [maskPasscode, setMaskPasscode] = useState(true);

  const tooltipTextHide = t('assistedApplication.eSignatureDetails.hide');
  const tooltipTextShow = t('assistedApplication.eSignatureDetails.show');

  const isInPersonSignature = signatureType === EsignSignatureType.inPerson;

  const enabledAuthMethods = features.eSignature.enabledTwoFactorAuthenticationTypes;
  const hasMultipleAuthMethods = enabledAuthMethods && enabledAuthMethods.length > 1 && !isInPersonSignature;

  const isPasscodeEnabled = isInPersonSignature || participant.authMethod === ESignSignerAuthMethod.passcode;
  const isCellphoneEnabled = !isInPersonSignature && participant.authMethod === ESignSignerAuthMethod.cellphone;

  const emailError = getFieldValidationError(SignerFormField.email, participant.email);
  const phoneNumberError = getFieldValidationError(SignerFormField.cellphone, participant.cellphone);
  const passcodeError = getFieldValidationError(SignerFormField.passcode, participant.passcode);

  const onSignerChange = useCallback(
    (data: { authMethod?: ESignSignerAuthMethod; cellphone?: string; email?: string; passcode?: string }) => {
      onUpdateSigner({
        ...participant,
        ...data,
      });
    },
    [onUpdateSigner, participant],
  );

  const onPasscodeDone = useCallback(
    (passcode?: string) => {
      const sanitizedPasscode = passcode?.replace(/\s+/g, ' ').trim();

      onUpdateSigner({
        ...participant,
        passcode: sanitizedPasscode,
      });
    },
    [onUpdateSigner, participant],
  );

  return (
    <div>
      <SignerInfoHeader signer={participant} />
      <Box px={2}>
        <Box mb={2} maxWidth={310}>
          <StyledTextInput
            fullWidth
            required
            inputVariant='outlined'
            label={t('inputs.email')}
            error={!!emailError}
            validationError={emailError}
            value={participant.email}
            disabled={disabled}
            onChange={(event) => onSignerChange({ email: event.target.value })}
            inputTooltip={
              participant.roles.some((role) => role.type === ParticipantRoles.AGENT)
                ? {
                    icon: (
                      <Icon name='info' variant='primary' width='16' height='16' imageType={ImageTypes.decorative} />
                    ),
                    text: t('assistedApplication.eSignatureDetails.emailInfo'),
                  }
                : undefined
            }
          />
        </Box>
        {hasMultipleAuthMethods && (
          <AuthMethodContainer>
            <Typography variant='body3'>{t('assistedApplication.eSignatureDetails.authMethod')}</Typography>
            <RadioGroup
              row
              value={participant.authMethod}
              onChange={(e) =>
                onSignerChange({
                  authMethod: e.target.value as ESignSignerAuthMethod,
                  cellphone: phoneNumberError === undefined ? participant.cellphone : undefined,
                })
              }
              sx={{ marginLeft: '30px' }}
            >
              {enabledAuthMethods.map((method) => {
                return (
                  <StyledFormControlLabel
                    key={method}
                    control={<Radio size='small' />}
                    label={t(`assistedApplication.eSignatureDetails.${method}`)}
                    value={method}
                    checked={method === participant.authMethod}
                  />
                );
              })}
            </RadioGroup>
          </AuthMethodContainer>
        )}
        <Box display='flex' alignItems='flex-end'>
          {isCellphoneEnabled && (
            <Box maxWidth={150}>
              <PhoneInput
                name='signerInfo-phoneNumber'
                required={participant.authMethod === ESignSignerAuthMethod.cellphone}
                inputVariant='outlined'
                label={!hasMultipleAuthMethods ? t('inputs.cellNumber') : ''}
                validationError={phoneNumberError}
                value={participant.cellphone}
                disabled={disabled}
                onAnswerChange={(answer: string) => onSignerChange({ cellphone: answer })}
              />
            </Box>
          )}
          {isPasscodeEnabled && (
            <Box maxWidth={175} display='flex' alignItems='center'>
              <TextInput
                fullWidth
                type={maskPasscode ? 'password' : 'text'}
                required={participant.authMethod === ESignSignerAuthMethod.passcode}
                label={!hasMultipleAuthMethods ? t(`assistedApplication.eSignatureDetails.passcode`) : ''}
                inputVariant='outlined'
                error={!!passcodeError}
                validationError={passcodeError}
                value={participant.passcode}
                disabled={disabled}
                onChange={(event) => onSignerChange({ passcode: event.target.value })}
                onBlur={(event) => onPasscodeDone(event.target.value)}
                inputTooltip={{
                  icon: (
                    <IconButton onClick={() => setMaskPasscode(!maskPasscode)}>
                      <Icon
                        name={maskPasscode ? 'visibility' : 'visibilityOff'}
                        variant='primary'
                        stroke='none'
                        imageType={ImageTypes.decorative}
                        width='25px'
                        height='25px'
                      />
                    </IconButton>
                  ),
                  text: maskPasscode ? tooltipTextShow : tooltipTextHide,
                }}
                labelTooltip={
                  isInPersonSignature
                    ? {
                        icon: (
                          <Icon
                            name='info'
                            variant='primary'
                            width='16'
                            height='16'
                            imageType={ImageTypes.decorative}
                          />
                        ),
                        text: t('assistedApplication.eSignatureDetails.inPerson.passcodeTooltip'),
                      }
                    : undefined
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
}
