import { PropsWithChildren, ReactElement, createContext, useContext, useState } from 'react';

import { JetDecisionOutcomes } from '@breathelife/types';

export type UseJetDecisionOutcomeAPI = {
  isFetching: boolean;
  setIsFetching: (value: boolean) => void;
  jetDecisionOutcomes: JetDecisionOutcomes[];
  setJetDecisionOutcomes: (jetDecisionOutcomes: JetDecisionOutcomes[]) => void;
};

export const JetDecisionOutcomeContext = createContext<UseJetDecisionOutcomeAPI | undefined>(undefined);

export function useJetDecisionOutcomes(): UseJetDecisionOutcomeAPI {
  const context = useContext(JetDecisionOutcomeContext);

  if (typeof context === 'undefined') {
    throw new Error('useJetDecisionOutcomes must be used within an JetDecisionOutcomesProvider');
  }

  return context;
}

export function JetDecisionOutcomesProvider(props: PropsWithChildren): ReactElement | null {
  const [outcomes, setOutcomes] = useState<JetDecisionOutcomes[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);

  return (
    <JetDecisionOutcomeContext.Provider
      value={{
        isFetching: fetching,
        setIsFetching: setFetching,
        jetDecisionOutcomes: outcomes,
        setJetDecisionOutcomes: setOutcomes,
      }}
    >
      {props.children}
    </JetDecisionOutcomeContext.Provider>
  );
}
