import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '../../../../Components/Typography';
import { ReactHtmlParser } from '@breathelife/ui-components';

export function SubmissionErrorView(): ReactElement {
  const { t } = useTranslation();
  return (
    <Box>
      <Box my={3}>
        <Typography variant='body1' grey={70}>
          <ReactHtmlParser html={t('modals.assistedApplication.submitApplicationView.errorView.somethingWentWrong')} />
        </Typography>
      </Box>
    </Box>
  );
}
