import axios, { AxiosError } from 'axios';

import { AssistedApplicationErrorIds, ApplicationSupportErrorIds, ApplicationCopyErrorIds } from '@breathelife/errors';

type ErrorIds = AssistedApplicationErrorIds | ApplicationSupportErrorIds | ApplicationCopyErrorIds;

const errorIdToTranslationKeyMap: Record<ErrorIds, string> = {
  [AssistedApplicationErrorIds.AnswersSafeguardBadRequestAnswersUpdatedAtMissing]:
    'notifications.badRequestErrorWhileSavingAnswersTimestampMissing',
  [AssistedApplicationErrorIds.AnswersSafeguardBadRequestAnswersUpdatedAtMismatch]:
    'notifications.badRequestErrorWhileSavingAnswersTimestampMismatch',
  [AssistedApplicationErrorIds.AnswersSafeguardUnexpectedError]: 'notifications.unexpectedErrorWhileSavingAnswers',
  [AssistedApplicationErrorIds.AnswersSafeguardApplicationNotFound]:
    'notifications.applicationNotFoundWhileSavingAnswers',

  [AssistedApplicationErrorIds.MissingRequiredInputs]: 'notifications.failedDueToMissingNodeIds',

  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestFirstNameEmpty]:
    'notifications.identityVerificationToast.validationErrors.firstName.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestLastNameEmpty]:
    'notifications.identityVerificationToast.validationErrors.lastName.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBYearMin]:
    'notifications.identityVerificationToast.validationErrors.DOB.year.min',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBMonthMin]:
    'notifications.identityVerificationToast.validationErrors.DOB.month.min',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBMonthMax]:
    'notifications.identityVerificationToast.validationErrors.DOB.month.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBDayMin]:
    'notifications.identityVerificationToast.validationErrors.DOB.day.min',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestDOBDayMax]:
    'notifications.identityVerificationToast.validationErrors.DOB.day.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneClassificationType]:
    'notifications.identityVerificationToast.validationErrors.telephone.classification.type',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneClassificationEmpty]:
    'notifications.identityVerificationToast.validationErrors.telephone.classification.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneAreaCodePattern]:
    'notifications.identityVerificationToast.validationErrors.telephone.areaCode.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneAreaCodeMax]:
    'notifications.identityVerificationToast.validationErrors.telephone.areaCode.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneAreaCodeEmpty]:
    'notifications.identityVerificationToast.validationErrors.telephone.areaCode.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneExchangePattern]:
    'notifications.identityVerificationToast.validationErrors.telephone.exchange.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneExchangeMax]:
    'notifications.identityVerificationToast.validationErrors.telephone.exchange.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneExchangeEmpty]:
    'notifications.identityVerificationToast.validationErrors.telephone.exchange.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneNumberPattern]:
    'notifications.identityVerificationToast.validationErrors.telephone.number.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneNumberMax]:
    'notifications.identityVerificationToast.validationErrors.telephone.number.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneNumberEmpty]:
    'notifications.identityVerificationToast.validationErrors.telephone.number.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneExtensionPattern]:
    'notifications.identityVerificationToast.validationErrors.telephone.extension.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestTelephoneExtensionMax]:
    'notifications.identityVerificationToast.validationErrors.telephone.extension.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressAddressIdPattern]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.idPattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetNumberPattern]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.number.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetNumberMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.number.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetNamePattern]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.name.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetNameMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.name.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetAddress1Max]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.address1.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetAddress1Empty]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.address1.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStreetAddress2Max]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.address2.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressCityMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.city.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressCityEmpty]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.city.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStateMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.state.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressStateEmpty]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.street.state.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressZip5Max]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.zip5.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressZip5Empty]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.zip5.empty',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressCountyPattern]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.county.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressCountyMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.county.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressCountryMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.country.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressUnitNumberPattern]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.unitNumber.pattern',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressUnitNumberMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.unitNumber.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestCurrentAddressPostalCodeMax]:
    'notifications.identityVerificationToast.validationErrors.currentAddress.postalCode.max',
  [AssistedApplicationErrorIds.IdentityVerificationInvalidRequestSSNEmpty]:
    'notifications.identityVerificationToast.validationErrors.ssn.empty',
  [AssistedApplicationErrorIds.IdentityVerificationServerTimout]:
    'notifications.identityVerificationToast.serverErrors.timeoutError',
  [AssistedApplicationErrorIds.IdentityVerificationAddressError]:
    'notifications.identityVerificationToast.serverErrors.addressError',
  [AssistedApplicationErrorIds.IdentityVerificationProcessingError]:
    'notifications.identityVerificationToast.serverErrors.processError',
  [AssistedApplicationErrorIds.IdentityVerificationApplicationNotFound]:
    'notifications.identityVerificationToast.serverErrors.applicationNotFound',

  [ApplicationSupportErrorIds.ApplicationSubmissionResetApplicationNotFound]:
    'notifications.applicationSupport.resetStatus.error.applicationNotFound',
  [ApplicationSupportErrorIds.ApplicationSubmissionResetInvalidApplicationID]:
    'notifications.applicationSupport.resetStatus.error.invalidApplicationId',
  [ApplicationSupportErrorIds.ApplicationSubmissionResetMissingApplicationID]:
    'notifications.applicationSupport.resetStatus.error.missingApplicationId',
  [ApplicationSupportErrorIds.ApplicationSubmissionResetApplicationSubmitted]:
    'notifications.applicationSupport.resetStatus.error.notMarkedAsSubmitted',
  [ApplicationSupportErrorIds.ApplicationSubmissionResetApplicationCompleted]:
    'notifications.applicationSupport.resetStatus.error.applicationCompleted',
  [ApplicationSupportErrorIds.ApplicationSubmissionResetNoCompletedESignCeremony]:
    'notifications.applicationSupport.resetStatus.error.noCompletedESignCeremonies',

  [ApplicationCopyErrorIds.ApplicationCopyThresholdInDaysMissingConfigError]:
    'notifications.copyApplication.copyApplicationMissingConfigThresholdInDays',
  [ApplicationCopyErrorIds.ApplicationCopyStartDateMissingConfigError]:
    'notifications.copyApplication.copyApplicationMissingConfigStartDate',
  [ApplicationCopyErrorIds.ApplicationCopyThresholdInDaysError]:
    'notifications.copyApplication.copyApplicationDisabledThresholdInDays',
  [ApplicationCopyErrorIds.ApplicationCopyStartDateError]:
    'notifications.copyApplication.copyApplicationDisabledStartDate',
  [ApplicationCopyErrorIds.ApplicationDisabledForLineOfBusiness]:
    'notifications.copyApplication.copyApplicationIsDisabledForLineOfBusiness',
};

export function getTranslationKeyFromErrorId(error: unknown): string | undefined {
  if (!axios.isAxiosError(error)) {
    return undefined;
  }
  const axiosError = error as AxiosError<any>;
  const errorId: ErrorIds | undefined = axiosError.response?.data?.errors?.errorId;

  if (!errorId) {
    return undefined;
  }

  return errorIdToTranslationKeyMap[errorId];
}

export function getTranslationKeysFromErrorIds(error: unknown): string[] | undefined {
  if (!axios.isAxiosError(error)) {
    return undefined;
  }
  const axiosError = error as AxiosError<any>;
  const errors: { errorId: ErrorIds }[] | undefined = axiosError.response?.data?.errors;

  if (!errors) {
    return undefined;
  }

  const translationKeys = errors.map((error) => errorIdToTranslationKeyMap[error.errorId]);

  return translationKeys;
}

export function getErrorIdsFromError(error: unknown): ErrorIds[] | undefined {
  if (!axios.isAxiosError(error)) {
    return undefined;
  }

  const errors: { errorId: ErrorIds }[] | { errorId: ErrorIds } | undefined = error.response?.data?.errors;
  if (!errors) return undefined;

  return Array.isArray(errors) ? errors.map((error) => error.errorId) : [errors.errorId];
}
