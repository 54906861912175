import { useTranslation } from 'react-i18next';
import { useContext, useMemo } from 'react';

import { NodeDetail } from '../../../../../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { Suggestions, SuggestionsOption } from '@breathelife/ui-components';
import { QuestionnaireEditorContext } from '../../../../ContextProvider/QuestionnaireEditorContextProvider';

type AnswerNodeIdInputProps = {
  nodeIds: NodeDetail[];
  selectedNodeId: string;
  handleChange: (newNodeId: string | undefined) => void;
};

export function AnswerNodeIdInput({
  selectedNodeId,
  handleChange,
  nodeIds,
}: AnswerNodeIdInputProps): React.ReactElement {
  const { t } = useTranslation();
  const { selectedLanguage } = useContext(QuestionnaireEditorContext);

  const options = useMemo(() => {
    const opts: SuggestionsOption[] = [];

    for (const detail of nodeIds) {
      const group = detail.groupName ? detail.groupName[selectedLanguage] || '' : '';
      if (!opts.find((e) => e.group === group && e.value === detail.answerNodeId)) {
        opts.push({
          value: detail.answerNodeId,
          label: detail.answerNodeId,
          group: group,
        });
      }
    }
    return opts;
  }, [nodeIds, selectedLanguage]);

  return (
    <Suggestions
      label={t('admin.questionnaireManagement.input.answerStorageNodeId')}
      disabled={false}
      value={selectedNodeId}
      options={options}
      onChange={handleChange}
      required
    />
  );
}
