import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCarrierContext, useLanguage } from '../../../../Hooks';
import styled from '../../../../Styles/themed-styled-components';
import { ReactHtmlParser } from '@breathelife/ui-components';

const StyledImg = styled.img`
  margin-top: 10px;
`;

const StyledDiv = styled.div`
  margin-top: 10px;
`;

export function NotificationCentreHelp(): ReactElement {
  const { t } = useTranslation();
  const language = useLanguage();
  const {
    carrierInfo: { companyName },
    features,
  } = useCarrierContext();

  const cloudinaryImg = useMemo(() => {
    if (features.notificationCentre.enabled) {
      return features.notificationCentre.cloudinaryImages;
    }
    return;
  }, [features.notificationCentre]);

  return (
    <StyledDiv>
      <ReactHtmlParser
        html={t(`notificationCentre.helpPopover.main`, {
          companyName,
        })}
      />
      <ReactHtmlParser html={t(`notificationCentre.helpPopover.informational.content`)} />
      <p>
        {cloudinaryImg && (
          <StyledImg
            alt={t(`notificationCentre.helpPopover.informational.alt`)}
            src={cloudinaryImg['informational'][language]}
            width='100%'
          />
        )}
      </p>
      <ReactHtmlParser html={t(`notificationCentre.helpPopover.actionable.content`)} />
      <p>
        {cloudinaryImg && (
          <StyledImg
            alt={t(`notificationCentre.helpPopover.actionable.alt`)}
            src={cloudinaryImg['actionable'][language]}
            width='100%'
          />
        )}
      </p>
    </StyledDiv>
  );
}
