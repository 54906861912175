import _ from 'lodash';
import { ReactElement, Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../Styles/themed-styled-components';

import { isRenderingOptionField, RenderingField, RenderingQuestion } from '@breathelife/questionnaire-engine';
import { FieldTypes } from '@breathelife/types';
import { ImageTypes, ReactHtmlParser } from '@breathelife/ui-components';

import { GhostButton } from '../../../../../Components/Button/GhostButton';
import { Icon } from '../../../../../Components/Icons';
import Typography from '../../../../../Components/Typography';
import getCurrency from '../../../../../Helpers/currency';
import { useCarrierContext } from '../../../../../Hooks';
import { useAssistedApplicationContext } from '../../../../../Hooks/useAssistedApplicationContext';
import { toCurrency } from '../../../../../Localization/utils';
import { CardBody, CardContainer, CardExpand, CardField, CardGrid, CardHeader } from './Styles';

type FieldGroupCardPreviewProps = {
  question: RenderingQuestion;
  onEdit: () => void;
  boldedBorder: boolean;
};

export function FieldGroupCardPreview(props: FieldGroupCardPreviewProps): ReactElement {
  const { question, onEdit, boldedBorder } = props;
  const { countryCode } = useCarrierContext();
  const { areAllFieldsDisabled } = useAssistedApplicationContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const { previewFields, expandedFields } = useMemo(() => {
    const visibleFields = question.fields.filter((field) => field.visible && field.type !== FieldTypes.information);

    // If any configured fields are visible, use them
    const { preview, expanded } = _.groupBy(visibleFields, (field) =>
      field.displayInCardPreview ? 'preview' : 'expanded',
    );

    if (preview && preview.length > 0) {
      return {
        previewFields: preview,
        expandedFields: expanded || [],
      };
    }

    // If not, fallback on the first four visible fields
    const defaultPreview = [...visibleFields];
    const defaultExpanded = defaultPreview.splice(4);

    return {
      previewFields: defaultPreview,
      expandedFields: defaultExpanded,
    };
  }, [question.fields]);

  const fields = useMemo(
    () => (isExpanded ? [...previewFields, ...expandedFields] : previewFields),
    [previewFields, expandedFields, isExpanded],
  );

  const showError = useMemo(() => _.some(question.fields, 'validationError'), [question.fields]);

  const formatFieldValue = useCallback(
    (field: RenderingField) => {
      const options = isRenderingOptionField(field) ? field.options : [];

      switch (field.type) {
        case FieldTypes.checkboxGroup:
          const values = (field.value as string[]).map((value) => _.find(options, { id: value })?.text);
          return values.filter(Boolean).join(', ');
        case FieldTypes.dropdown:
        case FieldTypes.radio:
          return _.find(options, { id: field.value })?.text;
        case FieldTypes.money:
          return toCurrency(field.value, getCurrency(countryCode), { minimumFractionDigits: 0 });
        default:
          return field.value;
      }
    },
    [countryCode],
  );

  return (
    <Fragment>
      <CardContainer showError={showError} boldedBorder={boldedBorder}>
        <CardHeader>
          <Typography variant='h3'>{question.title ? <ReactHtmlParser html={question.title} /> : ''}</Typography>
          {!areAllFieldsDisabled && (
            <GhostButton buttonSize='small' onClick={onEdit} data-testid={`${question.id}-edit`}>
              {t('cta.edit')}
            </GhostButton>
          )}
        </CardHeader>
        <CardBody>
          <CardGrid>
            {fields.map((field) => (
              <CardField key={field.id}>
                <Typography variant='body5' grey={70}>
                  {field.title}
                </Typography>
                <Typography variant='body1'>{formatFieldValue(field)}</Typography>
              </CardField>
            ))}
          </CardGrid>
          {expandedFields.length > 0 && (
            <CardExpand
              variant='text'
              color='primary'
              endIcon={
                <Icon
                  name='chevronDown'
                  variant='primary'
                  fill='none'
                  rotation={isExpanded ? 180 : 0}
                  imageType={ImageTypes.decorative}
                />
              }
              aria-expanded={isExpanded}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {t('assistedApplication.fieldGroupCard.expand')}
            </CardExpand>
          )}
        </CardBody>
      </CardContainer>

      {showError && (
        <Typography component='p' variant='small1' color={theme.colors.red[50]}>
          {t('assistedApplication.fieldGroupCard.error')}
        </Typography>
      )}
    </Fragment>
  );
}
