import _ from 'lodash';
import { InstanceScope, NodeId, VersionedAnswers, YesNoValue } from '@breathelife/types';
import { QuestionnaireEngine } from '@breathelife/questionnaire-engine';
import { NodeIds } from '@breathelife/insurance-form-builder';
import { getErrorIdsFromError } from '../../Localization/errorIdsToTranslationMap';
import { AssistedApplicationErrorIds } from '@breathelife/errors';

type ShouldRefreshAllAnswersParams = {
  answers?: VersionedAnswers;
  currentProposedInsuredIndex: number;
  questionnaireEngine: QuestionnaireEngine;
  nodeIdsSkippingDebounce?: NodeId[];
  updatedNodeIds?: NodeId[];
};

// Checks whether the conditions are met to refresh all the answers
export function shouldRefreshAllAnswers({
  currentProposedInsuredIndex,
  questionnaireEngine,
  nodeIdsSkippingDebounce,
  updatedNodeIds,
}: ShouldRefreshAllAnswersParams): boolean {
  if (!_.isArray(nodeIdsSkippingDebounce) || nodeIdsSkippingDebounce.length === 0) return false;
  const updatedNodesSkippingDebounce = _.intersection(nodeIdsSkippingDebounce, updatedNodeIds);
  if (updatedNodesSkippingDebounce.length === 0) return false;
  const scope: InstanceScope = {
    [NodeIds.insuredPeople]: currentProposedInsuredIndex,
  };
  return updatedNodesSkippingDebounce.some((nodeId) => {
    return questionnaireEngine.getAnswer(nodeId, scope) === YesNoValue.yes;
  });
}

// The backend throws this error if payload's application.answerUpdatedAt
// does not match db's application.answerUpdatedAt
export function isAnswerTimestampMismatchError(error: unknown): boolean {
  const errorIds = getErrorIdsFromError(error);

  return (
    Array.isArray(errorIds) &&
    errorIds.includes(AssistedApplicationErrorIds.AnswersSafeguardBadRequestAnswersUpdatedAtMismatch)
  );
}
