import { Box } from '@breathelife/mui';
import { ReactElement, useEffect } from 'react';

import { ESignCeremonyStatus, ESignSigner2FAInfo, ESignSignerAuthMethod, EsignSignatureType } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import { Application } from '../../../../Models/Application';
import { Drawer } from '../../../../Components/Drawer/Drawer';
import { useSelector, useCarrierContext } from '../../../../Hooks';
import { RequiredFile } from '../../../../Hooks/Application';
import { useGetESignCeremonyQuery } from '../../../../ReactQuery/ESignCeremony/eSignCeremony.queries';
import { ESignatureDetailsHeader } from './ESignatureDetailsHeader';
import { SignerInfoView } from './SignerInfo/SignerInfoView';
import { useProcessParticipantsQuery } from '../../../../ReactQuery/ParticipantProcessor/participantProcessor.queries';

type ESignatureDetailsViewProps = {
  application: Application | undefined;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onClickSend: () => void;
  onClickCancel: () => void;
  onUpdateSigner: (signer: ESignSigner2FAInfo, idx: number) => void;
  requiredFiles: RequiredFile[];
  signers: ESignSigner2FAInfo[];
  setSigners: (signers: ESignSigner2FAInfo[]) => void;
  signatureType: EsignSignatureType;
  setSignatureType: (signatureType: EsignSignatureType) => void;
};

export function ESignatureDetailsView(props: ESignatureDetailsViewProps): ReactElement {
  const isSubmitting = useSelector((state) => state.leadPlatform.submission.isSubmittingApplication);
  const {
    application,
    isLoading: isLoadingProp,
    isOpen,
    onClose,
    onClickSend,
    onClickCancel,
    onUpdateSigner,
    requiredFiles,
    signers,
    setSigners,
    signatureType,
    setSignatureType,
  } = props;
  const { features } = useCarrierContext();
  const ENABLE_DECOUPLE_ESIGN_FLOW = !!features.enableDecoupleESignFlow?.enabled;

  const enabledAuthMethods = features.eSignature.enabledTwoFactorAuthenticationTypes;
  const defaultAuthMethod = enabledAuthMethods?.[0] || ESignSignerAuthMethod.cellphone;

  const { data: eSignSigners, isLoading: isParticipantsLoading } = useProcessParticipantsQuery(application?.id, {
    enabled: isOpen && application !== undefined,
  });

  const { data: eSignCeremony, isLoading: isESignCeremonyLoading } = useGetESignCeremonyQuery(
    application?.id,
    features.signatureType,
    {
      refetchInterval: (eSignCeremony) => {
        // Refetch the ceremony until you get the signingUrl for in person signature
        if (
          !ENABLE_DECOUPLE_ESIGN_FLOW &&
          eSignCeremony?.inPerson &&
          !eSignCeremony?.signingUrl &&
          eSignCeremony?.status === ESignCeremonyStatus.IN_PROGRESS
        ) {
          return 3000;
        }

        if (!ENABLE_DECOUPLE_ESIGN_FLOW || eSignCeremony?.status !== ESignCeremonyStatus.IN_PROGRESS) {
          return false;
        }

        return 3000;
      },
    },
  );

  const onSignatureTypeChange = (signatureType: EsignSignatureType) => {
    setSignatureType(signatureType);
    if (signatureType === EsignSignatureType.inPerson) {
      setSigners(
        signers.map((signer) => {
          return { ...signer, authMethod: ESignSignerAuthMethod.passcode };
        }),
      );
    }
  };

  useEffect(() => {
    if (eSignSigners && eSignCeremony) {
      setSignatureType(eSignCeremony.inPerson ? EsignSignatureType.inPerson : EsignSignatureType.remote);
      setSigners(
        eSignSigners.map((signer) => {
          return {
            ...signer,
            authMethod: signer.authMethod ?? defaultAuthMethod,
          };
        }),
      );
    }
  }, [eSignSigners, eSignCeremony, defaultAuthMethod, setSignatureType, setSigners]);

  const isLoading = isESignCeremonyLoading || isLoadingProp || isSubmitting || isParticipantsLoading;

  if (!application) {
    return <Loader />;
  }

  const isEditMode =
    eSignCeremony?.status === ESignCeremonyStatus.DRAFT ||
    (ENABLE_DECOUPLE_ESIGN_FLOW &&
      (eSignCeremony?.status === ESignCeremonyStatus.IN_PROGRESS ||
        eSignCeremony?.status === ESignCeremonyStatus.READY));

  return (
    <Drawer open={isOpen} onClose={onClose}>
      {isOpen && (
        <Box p={4}>
          <ESignatureDetailsHeader
            isLoading={isLoading}
            status={eSignCeremony?.status}
            onClose={onClose}
            onClickSend={onClickSend}
            onClickCancel={onClickCancel}
            requiredFiles={requiredFiles}
          />
          <SignerInfoView
            eSignCeremony={eSignCeremony}
            signatureType={signatureType}
            onSignatureTypeChange={onSignatureTypeChange}
            signers={signers}
            isEditMode={isEditMode}
            isLoading={isLoading}
            isESignCeremonyLoading={isESignCeremonyLoading}
            onUpdateSigner={onUpdateSigner}
            application={application}
            requiredFiles={requiredFiles}
          />
        </Box>
      )}
    </Drawer>
  );
}
