import i18next from 'i18next';

import { Answers, Language } from '@breathelife/types';

import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { productsSlice } from '../../ReduxStore/Products/ProductsSlice';
import { Dispatch } from '../../ReduxStore/types';
import { getProducts } from '../../Services/ProductsService';
import { getQuotes } from '../../Services/QuotesService';

export const fetchProducts =
  (applicationId: string, lang?: Language) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(productsSlice.actions.setIsLoadingProducts(true));

    try {
      const products = await getProducts(applicationId, lang);
      dispatch(productsSlice.actions.setApplicationId(applicationId));
      dispatch(productsSlice.actions.setProducts(products));
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToLoadProducts'),
        }),
      );
    } finally {
      dispatch(productsSlice.actions.setIsLoadingProducts(false));
    }
  };

export const fetchQuotes =
  (applicationId: string, answers: Answers, answersV2: Answers, coverageAmount?: number | null) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!applicationId) return;

    dispatch(productsSlice.actions.setIsLoadingQuotes(true));
    try {
      const response = await getQuotes({ appId: applicationId, coverageAmount, answers, answersV2 });
      dispatch(productsSlice.actions.setQuotes(response));
    } catch (error: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: i18next.t('notifications.failedToFetchQuotes'),
        }),
      );
    } finally {
      dispatch(productsSlice.actions.setIsLoadingQuotes(false));
    }
  };

export const resetProducts =
  () =>
  (dispatch: Dispatch): void => {
    dispatch(productsSlice.actions.reset());
  };
