import { ReactElement, useCallback, useEffect } from 'react';

import { TypewriterTracking } from '@breathelife/frontend-tracking';
import { MessageType, Notification as BaseNotification, ReactHtmlParser } from '@breathelife/ui-components';

import { useDispatch, useSelector } from '../../Hooks';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';

export function Notification(): ReactElement | null {
  const dispatch = useDispatch();
  const notification = useSelector((store) => {
    return store.leadPlatform.notification;
  });
  useEffect(() => {
    if (notification.type === MessageType.error) {
      TypewriterTracking.errorOccurred({
        error: notification.message ?? '',
        hashedId: null,
      });
    }
  }, [notification]);
  const resetError = useCallback(() => dispatch(notificationSlice.actions.setNotification({})), [dispatch]);

  if (!notification.type) return null;

  return (
    <BaseNotification
      dataTestId={notification.dataTestId}
      display={!!notification.message}
      title={notification.title}
      message={<ReactHtmlParser html={notification.message || ''} />}
      type={notification.type}
      autoHideDuration={notification.autoHideDuration}
      onClose={resetError}
      position={{
        vertical: 'top',
        horizontal: 'right',
      }}
    />
  );
}
